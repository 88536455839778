import { useProducts } from "@tellescope/react-components"
import { Product } from "@tellescope/types-client"
import React, { useCallback, useEffect, useState } from "react"

export const useCart_____internal = () => {
  const [, { findById: findProduct }] = useProducts({ dontFetch: true })
  const [productIds, setProductIds] = useState<string[]>(() => {
    try {
      return (
        window.localStorage.cart
          ? JSON.parse(window.localStorage.cart)
          : []
      )
    } catch(err) {
      return []
    }  
  })

  useEffect(() => {
    try {
      window.localStorage.cart = JSON.stringify(productIds) 
    } catch(err) {}
  }, [productIds])

  const resetCart = React.useCallback(() => {
    setProductIds([])
  }, [])
  const addToCart = React.useCallback((productId: string) => {
    setProductIds(ps => [...ps, productId].sort((p1, p2) => p1.localeCompare(p2)))
  }, [])
  const removeFromCart = React.useCallback((productId: string) => {
    setProductIds(ps => {
      const toReturn = [] as typeof ps

      let didRemove = false
      for (const p of ps) {
        if (!didRemove && p === productId) {
          didRemove = true
          continue
        }
        toReturn.push(p)
      }

      return toReturn
    })
  }, [])

  const getProductsByCategory = useCallback((products: Product[]) => {
    const byCategory: Record<string, Product[]> = {}

    for (const p of products) {
      const category = p.categories?.[0] || 'Other'

      if (!byCategory[category]) {
        byCategory[category] = []
      }
      byCategory[category].push(p)
    }

    return byCategory
  }, [productIds, findProduct])

  const getProductsInCart = useCallback(() => {
    const byId: Record<string, { product: Product, count: number }> = {}

    for (const p of productIds) {
      const product = findProduct(p)
      if (!product) continue

      if (!byId[p]) {
        byId[p] = { product, count: 0 }
      }
      byId[p].count++
    }

    return byId
  }, [productIds, findProduct])

  return {
    productIds, getProductsByCategory, getProductsInCart,
    addToCart, removeFromCart, resetCart,
  }
}

const CartContext = React.createContext({} as ReturnType<typeof useCart_____internal>)
export const WithCart = ({ children } : { children: React.ReactNode }) => (
  <CartContext.Provider value={useCart_____internal()}>
    {children}
  </CartContext.Provider>
)
export const useCart = () => React.useContext(CartContext)
