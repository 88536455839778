import React, { useEffect, useState } from "react"
import {
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material"
import { useFullHeight, useIsMobile } from "../components/navigation"
import { useSearchParams } from "react-router-dom"
import { 
  PostEditor, 
  ForumsList, 
  ForumView, 
  IconModal, 
  LoadingLinear, 
  PostView, 
  useForums, 
  useModalIconButton, 
  value_is_loaded,
  useCalendarEvents,
  Styled,
  useRSVPActions,
  RSVPAvatar,
  upcoming_events_sorted,
} from "@tellescope/react-components"
import { routes, useGoBack, useNavigateToPage } from "../definitions/routes"
import { ResponsiveModal } from "../components/layout"
import { AddCircleIcon } from "../definitions/icons"
import { CalendarEvent } from "@tellescope/types-client"
import { formatted_date } from "@tellescope/utilities"
import { CreateChatRoom } from "@tellescope/chat"
import { ChatIcon } from "../components/icons"
import { dashboardChildStyles } from "../definitions/constants"

const EVENT_WIDTH = '400px'
export const PublicEvent = ({ event } : { event: CalendarEvent }) => {
  const navigate = useNavigateToPage()

  const { rsvps, selfRSVP, addRsvp, removeRsvp } = useRSVPActions(event)
  const rsvp1 = rsvps[0]
  const rsvp2 = rsvps[1]

  return (
    <Paper elevation={6} sx={{ padding: '20px', minWidth: EVENT_WIDTH }}>
    <Grid container direction="column" wrap="nowrap">
      <Grid item onClick={() => navigate(routes.video, { query: { calendarEventId: event.id } } )}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          }
        }}
      >
      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" sx={{
        maxHeight: 100,
        maxWidth: EVENT_WIDTH,
        overflowY: 'auto'
      }}>
        <Typography component="h1" sx={{ fontSize: 18, fontWeight: 600 }}>
          {event.title}
        </Typography>

        <Typography style={{ opacity: 0.75, fontSize: 15, minWidth: 150 }}>
          {formatted_date(new Date(event.startTimeInMS))}
        </Typography>
      </Grid>

      <Typography sx={{
        fontSize: 15,
        mb: 1,
        maxHeight: 150,
        maxWidth: EVENT_WIDTH,
        overflowY: 'auto',
      }}>
        {event.description}
      </Typography>
      </Grid>

      <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Grid container alignItems="center" wrap="nowrap" spacing={0.5}>
          {rsvp1 && 
            <Grid item>
              <RSVPAvatar rsvp={rsvp1} />
            </Grid>
          }
          {rsvp2 && 
            <Grid item>
              <RSVPAvatar rsvp={rsvp2} />
            </Grid>
          }   

          <Grid item>
          <Typography>
            {event.numRSVPs} Attending
          </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
        <FormControlLabel labelPlacement="start" control={
          <Switch 
            checked={!!selfRSVP} 
            onChange={() => 
              (selfRSVP ? removeRsvp : addRsvp)()
              .catch(console.error) 
            }
          />
        } 
          label="I'm Going" 
        />
        </Grid>
        </Grid>

    </Grid>
    </Paper>
  )
}

export const PublicEvents = ({ style } : Styled) => {
  const [, { filtered }] = useCalendarEvents({ loadFilter: { publicRead: true } })

  const publicEventsLoading = filtered(e => !!e.publicRead)

  return (
    <LoadingLinear data={publicEventsLoading} render={events => 
      events.length === 0 
        ? <></> 
        : (
          <Grid container direction="column" style={style}>
            <Typography sx={{ fontSize: '20px', mb: 0.5 }}>
              Upcoming Events
            </Typography>

            <Grid container wrap="nowrap" sx={{
              overflowX: 'auto',
              maxWidth: '100vw',              
              paddingBottom: '2px', // padding for overflow scrollbar
            }}>
              {upcoming_events_sorted(events).map(e => 
                <Grid item key={e.id} sx={{ mr: 1.5 }}>
                  <PublicEvent key={e.id} event={e} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )
    } />
  )
}

export const WithCreateChatRoomFAB = ({ children } : { children: React.ReactNode }) => {
  const navigate = useNavigateToPage()
  const [open, setOpen] = useState(false)

  const isMobile = useIsMobile()

  return (
    <>
    <ResponsiveModal open={open} setOpen={setOpen} style={{ maxWidth: '700px' }}>
    <Grid container justifyContent="center">
    <Grid container sx={{ py: 2, maxWidth: '600px' }}>
      <CreateChatRoom dontIncludeSelf={false} roomTitle="Conversation" showTitleInput 
        searchBarPlacement={isMobile ? "bottom" : "top"}
        title={isMobile ? "" : "Start Conversation"}
        maxHeight={'35vh'}
        onSuccess={({ id }) => navigate(routes.communications, { id })}
        onGoBack={() => setOpen(false)} 
      />
    </Grid>
    </Grid>
    </ResponsiveModal>

    {children}

    {!open && 
      <Fab color="primary" aria-label="add" 
        onClick={() => setOpen(true)}
        sx={{
          position: 'absolute',
          bottom: isMobile ? 70 : 30,
          right: isMobile ? 16 : 30,
        }}
      >
        <ChatIcon /> 
      </Fab>
    }
    </>
  )
}

export const Community = () => {
  const height = useFullHeight()

  const [forumsLoading] = useForums()
  const [query] = useSearchParams()
  const goBack = useGoBack()
  const navigate = useNavigateToPage()

  const forumId = query.get('forumId')
  const postId = query.get('postId')

  useEffect(() => {
    if (!value_is_loaded(forumsLoading)) return
    if (forumId) return
    if (forumsLoading.value.length === 1) {
      navigate(routes.community, { query: { forumId: forumsLoading.value[0].id }})
    }
  }, [forumsLoading, forumId, navigate])

  const addPostProps = useModalIconButton({
    Icon: AddCircleIcon,
    label: "Add Post"
  })
 
  if (forumId && postId) return (
    <WithCreateChatRoomFAB>
    <Grid container style={dashboardChildStyles}>
    <PostView forumId={forumId} postId={postId} 
      maxHeightPost={`calc(${height} - 20vh)`}
      maxHeightComments={height}
      redirectLink={routes.community}
      onDelete={goBack}
    />
    </Grid>
    </WithCreateChatRoomFAB>
  )
  if (forumId) return (
    <WithCreateChatRoomFAB>
    <Grid container direction="column" style={dashboardChildStyles}>
      <PublicEvents style={{ marginBottom: 20 }} />

      <ForumView forumId={forumId} maxHeight={height}
        onClickPost={post => navigate(routes.community, { query: { forumId: post.forumId, postId: post.id } })}
        redirectLink={routes.community}
        TitleComponent={({ title, titleStyle }) => (
          <Grid container alignItems="center">
            <Typography style={{ ...titleStyle, marginRight: 3 }}>
              {title}
            </Typography>

            <IconModal {...addPostProps} ModalComponent={ResponsiveModal} style={{ 
              maxWidth: 650, 
              paddingTop: '8%', paddingBottom: '8%',
            }}> 
              <PostEditor forumId={forumId} onSuccess={() => addPostProps.setOpen(false)} />
            </IconModal>
          </Grid>
        )}
      />
    </Grid>
    </WithCreateChatRoomFAB>
  )
  return (
    <LoadingLinear data={forumsLoading} render={forums => (
    <WithCreateChatRoomFAB>
    <Grid container direction="column" style={dashboardChildStyles}>
      <PublicEvents style={{ marginBottom: 20 }} />
      
      <ForumsList forums={forums} maxHeight={height} onClick={forum => {
        navigate(routes.community, { query: { forumId: forum.id  } })
      }} />
    </Grid>
    </WithCreateChatRoomFAB>
    )} />
  )
}