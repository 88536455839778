import { useEnduserSession } from "@tellescope/react-components"
import { useEffect } from "react"

export const usePolling = (action: Function, delayInMS: number) => {
  useEffect(() => {
    const i = setInterval(async () => {
      try {
        await action()
      } catch(err) {
        console.error('error polling', err)
      }
    }, delayInMS)
    return () => { clearInterval(i) }
  }, [action, delayInMS])
}

export const useHomepageLink = () => {
  const session = useEnduserSession()

  const gender = session.userInfo?.gender?.toLowerCase() || ''
  if (gender.includes('fem') || gender.includes('wom')) {
    return 'https://choosejoi.co'
  }

  return 'https://blokes.co'
}