import React, { useMemo } from "react"
import { Button, Divider, Grid, Paper, Typography } from "@mui/material"
import { useProducts, value_is_loaded } from "@tellescope/react-components"
import { useNavigate, useParams } from "react-router-dom"
import { payment_cost_to_string, remove_script_tags } from "@tellescope/utilities"
import { useCart } from "./CartContext"
import { ViewCartIconButton } from "./CartIcons"
import { ProductCard } from "./Products"
import { routes } from "../../definitions/routes"

const divider = <Divider sx={{ borderWidth: 1, borderColor: '#999999' }} />
export const Product = () => {
  const navigate = useNavigate()
  const { addToCart, productIds } = useCart()
  const [productsLoading, { findById: findProduct }] = useProducts()

  const productId = useParams()?.productId ?? ''
  const product = findProduct(productId)

  if (!product) return null
  const count = productIds.filter(p => p === product.id).length

  const otherProducts = (
    value_is_loaded(productsLoading)
      ? productsLoading.value.filter(p => p.id !== productId).splice(0, 3)
      : []
  )

  return (
    <Grid container direction="column" spacing={2}
      sx={{ 
        px: { xs: 3, sm: 6, md: 7 },
        py: 2,
      }}
    >
      <Grid item>
      <Grid container alignItems="center" justifyContent={"space-between"}>
        <Typography sx={{ fontSize: 14, opacity: 0.85 }}>
          <Typography component="span" onClick={() => navigate(routes.home)}
            sx={{ fontSize: 'inherit', cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          >
            All
          </Typography> / {product.title}
        </Typography>

        <ViewCartIconButton />
      </Grid>        
      </Grid>

      <Grid item>{divider}</Grid>

      <Grid item>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
            {product.title}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
        <Grid container direction="column" spacing={1.5}>
          {product.description &&
            <Grid item>
              <Typography sx={{ fontSize: 15 }}>
                {product.description}
              </Typography>
            </Grid>
          }

          <Grid item>
            <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
              {payment_cost_to_string(product.cost)}
            </Typography>
          </Grid>

          <Grid item>
            <Typography sx={{ fontSize: 13.5 }}>
              + Free Shipping
            </Typography>
          </Grid>
        </Grid>
        </Grid>

        {product.image &&
          <Grid item xs={12} sm={4} sx={{ p: 2 }}>
            <img src={product.image} alt={product.title} style={{ width: '100%' }}/>    
          </Grid>
        }
      </Grid>
      </Grid>

      <Grid item>
        {/* paper as hack for white bg button while still responsive on hover */}
        <Paper elevation={1} sx={{ width: 140 }}>
        <Button variant="outlined" size="small" sx={{ width: 140 }}
          onClick={() => addToCart(product.id)} 
          disabled={!!product.maxCheckoutCount && count >= product.maxCheckoutCount}
        >
          Add to Cart
        </Button>
        </Paper>
      </Grid>

      <Grid item sx={{ my: 2 }}>{divider}</Grid>

      {product.htmlDescription &&
      <>
        <Grid item>
          <div dangerouslySetInnerHTML={{
            __html: remove_script_tags(product.htmlDescription)
          }} />
        </Grid> 
      
        <Grid item sx={{ my: 2 }}>{divider}</Grid>
      </>
      }

      {otherProducts.length > 0 &&
        <Grid item>
        <Grid container direction="column">
          <Grid item sx={{ mb: 1 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
              Other Products
            </Typography>
          </Grid>

          <Grid item>
          <Grid container spacing={2}>
          {otherProducts.map(p => (
            <Grid item key={p.id}>
              <ProductCard product={p} />
            </Grid>
          ))}
          </Grid>
          </Grid>
        </Grid>
        </Grid>
      }
    </Grid>
  )
}