import React from "react"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
export const TERMS_VERSION = '1.0'
export const TermsOfServiceAndPrivacyPolicyConsent = ({ value, onChange } : { 
  value: boolean, 
  onChange: React.Dispatch<React.SetStateAction<boolean>>,
}) => (
  <FormControlLabel 
    control={<Checkbox checked={value} onChange={() => onChange(t => !t)} />} 
    label={
      <Typography style={{ fontSize: 14 }}>
        I agree to the 
        <a href={"/terms-of-service"} target="_blank" rel="noopener noreferrer">Terms of Service</a> and 
        {' '}<a href={"/privacy-policy"} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </Typography>
    } 
  />
)