import React, { useState } from "react"
import { useCart } from "./CartContext"
import { Grid, Typography } from "@mui/material"
import { ToggleCartCount } from "./CartIcons"
import { useIsMobile } from "../../components/navigation"
import { payment_cost_to_string } from "@tellescope/utilities"
import Logo from "../../img/logo.png"
import { CheckoutButton } from "./Checkout"

export const CartPage = () => {
  const isMobile = useIsMobile()
  const { productIds, getProductsInCart } = useCart()
  const [checkingOut, setCheckingOut] = useState(false)

  const inCart = getProductsInCart()

  return (
    <Grid container direction="column" sx={{ p: 2 }} spacing={2}>
      {!checkingOut &&
        <>
        <Grid item>
          <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>
            Your Cart{productIds.length ? ` (${productIds.length})` : ''}
          </Typography>
        </Grid>

        {productIds.length === 0 &&
          <Grid item>
            <Typography>Your cart is empty</Typography>
          </Grid>
        }

        <Grid item>
        {Object.keys(inCart).map(productId => {
          const { product } = inCart[productId]

          return (
            <Grid item key={productId} sx={{ mb: 2 }}>
            <Grid container alignItems="center" spacing={2.5} wrap="nowrap">
              <Grid item>
              <Grid container alignItems="center" direction="column">
                <Grid item sx={{ 
                  p: isMobile ? 0.5 : 2, 
                  pb: 0.5, 
                  textAlign: 'center',
                  width: isMobile ? 100 : 200, 
                }}>
                  <img src={product.image || Logo} 
                    style={{ 
                      maxHeight: isMobile ? 50 : 100,  
                      maxWidth: isMobile ? 85 : 175 
                    }} 
                  />
                </Grid> 

                <Grid item sx={{ mt: 0.5 }}>
                  <ToggleCartCount product={product} />
                </Grid>
              </Grid> 
              </Grid> 

              <Grid item sx={{ pb: 2 }}>
              <Grid container direction="column">
                <Typography sx={{ fontSize: isMobile ? 17 : 20, fontWeight: 'bold' }}>
                  {product.title}
                  <span style={{ paddingLeft: 10, fontWeight: 'normal' }}>
                    {payment_cost_to_string(product.cost)}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: isMobile ? 13 : 15 }}>
                  {product.description}
                </Typography>
              </Grid>
              </Grid>
            </Grid>
            </Grid>
          )
        })}
        </Grid>
        </>
      }

      <Grid item sx={{ }}>
        <CheckoutButton onClick={() => setCheckingOut(true)} />
      </Grid>
    </Grid>
  )
}