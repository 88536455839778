import React, { useState } from "react"
import {
  Box,
  Grid, 
  Typography,
} from "@mui/material"
import { 
  DisplayPicture, 
  Modal,
  useDisplayPictureUploadForSelf,
  FileDropzone,
  useEnduserSession,
  LabeledIconButton,
  LoadingButton,
  FileBlob,
  useEnduserSessionContext,
  initial_values_for_input_schema,
  validation_for_input_schema,
  FormikSubmitButton,
} from "@tellescope/react-components"
import { EditIcon, EmailIcon, PersonIcon } from "../definitions/icons"
import { Formik } from "formik"
import { accountInfo } from "../definitions/schemas"
import { FormikTextField } from "../components/inputs"
import { Link } from "react-router-dom"

export const EditableAvatar = () => {
  const session = useEnduserSession()
  const { handleUpload, updating } = useDisplayPictureUploadForSelf()

  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(undefined as FileBlob | undefined)

  return (
    <>
    <Modal open={open} setOpen={setOpen}>
    <Grid container direction="column">
      <FileDropzone file={file} onChange={setFile} 
        style={{
          width: '100%',
          height: 250,
          border: '1px solid black',
          borderRadius: 15,
        }}
        dropzoneStyle={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
       />

      <LoadingButton submitting={updating} disabled={!file}
        submitText="Upload" submittingText="Uploading..." 
        onClick={async () => {
          await handleUpload(
            {
              name: file!.name,
              size: file!.size,
              type: file!.type, 
            }, 
            file!
          )

          setOpen(false)
        }}
      />
     </Grid>
    </Modal>

    <Box onClick={() => setOpen(true)} sx={{ 
      border: '2px solid', 
      borderColor: 'primary.main', 
      borderRadius: '100%', 
      padding: 1,
      cursor: 'pointer',
     }}>
      <DisplayPicture alt="self" user={session.userInfo} size={125} />
    </Box>
    <Box sx={{
      backgroundColor: 'primary.main',
      borderRadius: '100%',
      position: 'relative',
      bottom: 20
    }}>
      <LabeledIconButton color="white" 
        Icon={EditIcon} onClick={() => setOpen(true)} label="Upload Picture" 
      />
    </Box>
    </>
  )
}

const state_validation_message = "State must be 2 uppercase letters"
export const EnduserInfoEditor = ({ style, onboarding } : { style?: React.CSSProperties, onboarding?: boolean }) => {
  const { enduserSession: session, updateUserInfo } = useEnduserSessionContext()
  const [error, setError] = useState('')

  return (
    <Grid direction="column" style={style}>
    <Formik
      initialValues={initial_values_for_input_schema(accountInfo, {
        fname: session.userInfo.fname,
        lname: session.userInfo.lname,
        email: session.userInfo.email,
        phone: session.userInfo.phone,
        addressLineOne: session.userInfo.addressLineOne,
        addressLineTwo: session.userInfo.addressLineTwo,
        city: session.userInfo.city,
        state: session.userInfo.state,
        zipCode: session.userInfo.zipCode,
      })}
      validationSchema={validation_for_input_schema(accountInfo)}
      onSubmit={async (values, { resetForm }) => {
        if (values.state?.toUpperCase() !== values.state) {
          return setError(state_validation_message)
        }
        const userInfo = session.userInfo
        await (
          updateUserInfo(values)
          .then(() => {
            resetForm({ values })  // sets dirty to false, disabling submit button

            if ( 
              !!(values.addressLineOne && values.city && values.state && values.zipCode)
              && (
                 (values.addressLineOne !== userInfo.addressLineOne)
              || (values.addressLineTwo !== userInfo.addressLineTwo)
              || (values.city !== userInfo.city)
              || (values.state !== userInfo.state)
              || (values.zipCode !== userInfo.zipCode)
            )) {
              session._GET<any, { data: any }>('/v1/integrations/proxy-read', { integration: "Salesforce", type: 'address-update' })
              .catch(console.error)
            }
          })
          .catch((err: any) => setError(err?.message))
        )
      }}
    >{
      (formik) => 
      <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
        <FormikTextField formik={formik} field="fname" label="First Name" fullWidth required={onboarding}
          InputProps={{ startAdornment: <PersonIcon style={{ marginRight: 5, opacity: .5 }} /> }}           
        />
        </Grid>
        <Grid item xs={6}>
        <FormikTextField formik={formik} field="lname" label="Last Name" fullWidth required={onboarding}
          InputProps={{ startAdornment: <PersonIcon style={{ marginRight: 5, opacity: .5 }} /> }}           
        />
        </Grid>

        <Grid item xs={6}>
        <FormikTextField formik={formik} field="email" label="Email" required fullWidth 
          InputProps={{ startAdornment: <EmailIcon style={{ marginRight: 5, opacity: .5 }} /> }} 
        />
        </Grid>
        <Grid item xs={6}>
        <FormikTextField formik={formik} field="phone" label="Phone" fullWidth required={false}
          InputProps={{ startAdornment: <PersonIcon style={{ marginRight: 5, opacity: .5 }} /> }}           
        />
        </Grid>

        <Grid item xs={8}>
        <FormikTextField formik={formik} field="addressLineOne" label="Address" fullWidth required
        />
        </Grid>
        <Grid item xs={4}>
        <FormikTextField formik={formik} field="addressLineTwo" label="Line 2" fullWidth
        />
        </Grid>
        
        <Grid item xs={6}>
        <FormikTextField formik={formik} field="city" label="City" fullWidth required 
        />
        </Grid>
        <Grid item xs={3}>
        <FormikTextField formik={formik} field="state" label="State (2-letters)" fullWidth required
          onChange={() => {
            if (error === state_validation_message) {
              setError('')
            }
          }}
        />
        </Grid>
        <Grid item xs={3}>
        <FormikTextField formik={formik} field="zipCode" label="ZIP Code" fullWidth required
        />
        </Grid>

        <Grid item xs={12}>
        <FormikSubmitButton disabledIfUnchanged 
          disabled={onboarding && !(formik.values.fname && formik.values.lname)}
          formik={formik} submitText="Update Info" submittingText="Updating..." 
        />
        </Grid>

        <Grid item xs={12}>
        <Typography color="error">{error}</Typography>
        </Grid>
      </Grid>

        {/* {error &&
          <Grid item sx={{  }}>
          <Typography style={{ fontSize: 14 }}>
            <Link to="/logout" >
              Log out and log in to try again
            </Link>
          </Typography>
        </Grid>
        } */}
      </form>
    }</Formik>
    </Grid>
  )
}

export const Settings = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <Typography component="h2" sx={{
        fontSize: 25,
        fontWeight: 600,
        marginBottom: 1
      }}>
        Your Profile
      </Typography>

      <Typography sx={{ marginBottom: 3 }}>
        You can update this information at any time
      </Typography>

      <EditableAvatar />

      <EnduserInfoEditor style={{ marginTop: 12, paddingLeft: 40, paddingRight: 40, maxWidth: 600 }} />
    </Grid>
  )
}