import React, { useCallback, useEffect, useRef, useState } from "react"
import { LoadingButton, useEnduserSession } from "@tellescope/react-components"
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'; 
import { Button, Grid, Typography } from "@mui/material";
import { useCart } from "./CartContext";
import { routes } from "../../definitions/routes";
import { payment_cost_to_string } from "@tellescope/utilities";

const StripeForm = ({ businessName, disabled } : { businessName: string, disabled?: boolean }) => {
  const { getProductsInCart } = useCart()
  const stripe = useStripe();
  const elements = useElements()

  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event?.preventDefault();

    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: { 
        return_url: `${window.location.origin}${routes.purchases}`, 
      },
    });

    setLoading(false)

    if (error) {
      console.error(error)
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error?.message ?? 'Something went wrong');
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Grid container direction="column">
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" onReady={() => setReady(true)} 
          options={{
            business: { name: businessName },
          }}
        />

        <Typography sx={{ mt: 2, fontSize: 18, fontWeight: 'bold' }}>
          Your cart total is {payment_cost_to_string({
            amount: (
              Object.values(getProductsInCart())
              .reduce((t, v) => t + v.product.cost.amount * v.count, 0)
            ),
            currency: 'USD',
          })}
        </Typography>

        <Button variant="contained" color="primary" type="submit" sx={{ mt: 1 }}
          disabled={disabled || loading || !(stripe && ready)}
        >
          {loading 
            ? "Checking Out..."
            : "Complete Checkout"
          }
        </Button>
        {/* Show error message to your customers */}
        {errorMessage && 
          <Typography color="error" sx={{ mt: 0.5 }}>
            {errorMessage}
          </Typography>
        }
      </form>
    </Grid>
  )
}

export const CheckoutButton = ({ onClick } : { onClick: () => void }) => {
  const session = useEnduserSession()
  const { productIds } = useCart()
  const [clientSecret, setClientSecret] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [stripePromise, setStripePromise] = useState<ReturnType<typeof loadStripe>>()
  const refreshRef = React.useRef(productIds.length)

  const prepareCheckout = useCallback(() => {
    if (productIds.length === 0) return

    session.api.products.prepare_stripe_checkout({ productIds })
    .then(({ clientSecret, publishableKey, stripeAccount, businessName }) => {
      refreshRef.current = productIds.length
      setClientSecret(clientSecret)
      setStripePromise(loadStripe(publishableKey, { stripeAccount }))
      setBusinessName(businessName)
      onClick()
    })
    .catch(console.error)
  }, [productIds, session])

  // if cart changes, we need to generate a new PaymentIntent
  useEffect(() => {
    if (!clientSecret) return // have not fetched yet
    if (refreshRef.current === productIds.length) return
    refreshRef.current = productIds.length

    prepareCheckout() // get new payment intent
  }, [productIds, clientSecret, prepareCheckout])

  if (!(clientSecret && stripePromise)) {
    return (
      <LoadingButton variant="contained" style={{ width: '250px', height: 45 }}
        submitText="Begin Checkout" submittingText="Loading..."
        disabled={productIds.length === 0}
        onClick={prepareCheckout}
      />
    )
  }
  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <StripeForm businessName={businessName} />
    </Elements>
  )
}
