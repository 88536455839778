import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom"
import { Alert, Button, CircularProgress, Drawer, Grid, IconButton, MenuItem, MenuList, Paper, SxProps, Tab, Tabs, Typography } from "@mui/material";
import { DisplayPictureForSelf, Styled, useCalendarEvents, useEnduserSession, useMeetings, value_is_loaded } from "@tellescope/react-components";
import { useLocation } from "react-router-dom";
import { MOBILE_WIDTH_CUTOFF } from "../definitions/constants";
import { RouteName, Routes, routes, useNavigateToPage } from "../definitions/routes";
import { 
  ChatIcon, 
  CommunityIcon, 
  ContentIcon, 
  EventsIcon, 
  ForumIcon, 
  HomeIcon, 
  LogoutIcon, 
  SettingsIcon, 
} from "./icons";
import { Link } from "./Link";
import { BookAppointmentIcon, CarePlanIcon, FormIcon } from "../definitions/icons";
import { ResponsiveModal } from "./layout";
import Logo from "../img/logo.png"
import { useHomepageLink } from "../hooks";
import { CartIcon } from "../pages/ECommerce/CartIcons";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const TabNavigation = ({ 
  tabs, 
} : { 
  tabs: {
    label: string,
    component: React.ReactNode,
  }[]
}) => {
  const location = useLocation()
  const storedTab = tabs.findIndex(t => t.label === window.localStorage[location.pathname + 'tab'])

  const [tab, setTab] = useState(
    storedTab !== -1 ? storedTab : 0
  )

  useEffect(() => {
    window.localStorage[location.pathname + 'tab'] = tabs[tab].label
  }, [tab, tabs, location])

  return (
    <Grid container direction="column">
      <Paper>
      <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}> 
        {tabs.map((t, i) => (
          <Tab key={t.label} label={t.label} {...a11yProps(i)} /> 
        ))}
      </Tabs>
      </Paper>

      {tabs[tab].component}
    </Grid>
  )
}

// referencing example https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
const getWindowDimensions = () => ({ 
  width: window.innerWidth, height: window.innerHeight 
})
export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => { setDimensions(getWindowDimensions()) }

    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize) }
  }, []);

  return dimensions;
}
export const useWindowWidth = () => useWindowDimensions().width
export const useWindowHeight = () => useWindowDimensions().height

export const useFullHeight = () => (
  useIsMobile() ? '83vh' : '90vh'
)

export const useIsMobile = () => useWindowWidth() < MOBILE_WIDTH_CUTOFF

type Page <K extends keyof Routes> = { 
  value: Routes[K],
  Icon: typeof ForumIcon | typeof HomeIcon,
  label: string,
  subpages?: Page<any>[],
}
const buildNavigationInfo = <T extends { [K in RouteName]?: Page<K> }> (t: T) => t

export const navigationInfo = buildNavigationInfo({
  home: {
    value: routes.home,
    label: "Home",
    Icon: HomeIcon,
    subpages: [
      {
        value: routes.settings,
        label: "Profile",
        Icon: SettingsIcon,
      },
      {
        value: routes.events,
        label: "Consultations",
        Icon: EventsIcon,
      },
      // {
      //   value: routes.appointment_booking,
      //   label: "Schedule Consult",
      //   Icon: BookAppointmentIcon,
      // },
      {
        value: routes.documents,
        label: "Files",
        Icon: FormIcon,
      },
      // {
      //   value: routes.purchases,
      //   label: "Purchases",
      //   Icon: FormIcon,
      // },
      // {
      //   value: routes.cart,
      //   label: "Cart",
      //   Icon: FormIcon,
      // },
    ]
  },
  communications: {
    value: routes.communications,
    label: "Inbox",
    Icon: ChatIcon,
  },
  content: {
    value: routes.content,
    label: "Resources",
    Icon: ContentIcon,
  },
  documents: {
    value: routes.documents,
    label: "Documents",
    Icon: FormIcon,
  },
  community: {
    value: routes.community,
    label: "Community",
    Icon: CommunityIcon,
  },
  care_plan: {
    value: routes.care_plan,
    label: "Care Plan",
    Icon: CarePlanIcon,
  },
  events: {
    value: routes.events,
    label: "My Events",
    Icon: EventsIcon,
  },
  appointment_booking: {
    value: routes.appointment_booking,
    label: "Book an Appointment",
    Icon: BookAppointmentIcon,
  },
  settings: {
    value: routes.settings,
    label: "Settings",
    Icon: SettingsIcon,
  },
  logout: {
    value: routes.logout,
    label: "Log Out",
    Icon: LogoutIcon,
  },
})

const SIDEBAR_ICON_SIZE = 40;

const MenuItemForPage = ({ page, iconOnly, onClick, onNavigate, style } : { page: Page<any>, iconOnly?: boolean, onNavigate?: () => void, onClick?: () => void } & Styled) => {
  const navigate = useNavigateToPage()
  const location = useLocation()

  const isSelected = location.pathname.includes(page.value)
  const isSelectedOrSubpage = !!(
    isSelected || page.subpages?.find(p => location.pathname.includes(p.value))
  )
  return (
    <MenuItem key={page!.label} 
      sx={{ 
        paddingTop: '8px', paddingBottom: '8px', 
        paddingLeft: '30px',
        cursor: 'default',
        '&:hover': {
          backgroundColor: 'inherit',
        },
        ...style 
      }}
      // selected={location.pathname.includes(p.value)}
    >
    <Grid container direction="column" sx={{ overflowX: 'hidden' }}>
      <Grid container alignItems="center" 
        onClick={() => {
          if (onClick) return onClick()

          navigate(page.value)
          onNavigate?.()
        }}
        sx={{
          cursor: !isSelected ? 'pointer' : undefined,
          '&:hover': {
            textDecoration: 'underline',
          }
        }}
      >
        {<page.Icon 
          sx={{ 
            fontSize: SIDEBAR_ICON_SIZE,
            marginRight: 3,
            opacity: isSelectedOrSubpage ? 1 : .4,
            color: 'white',
            backgroundColor: (
              page.value === routes.logout 
                ? '#c9c9c5'
                : 'secondary.main' 
            ),
            borderRadius: '100%',
            p: 0.75,
          }} 
        />}


        {!iconOnly && 
          <Typography style={{ 
            opacity: isSelectedOrSubpage ? 1 : .7,
            textDecoration: isSelected ? 'underline' : undefined,
            fontSize: 18,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
            {page!.label}  
          </Typography>
        }
      </Grid>

      {isSelectedOrSubpage && (page.subpages?.map(p => (
        <Grid item key={p.value} sx={{ ml: '75px' }}>
        <Typography 
          sx={{ 
            fontSize: 18,
            color: location.pathname.includes(p.value) ? 'black' : '#444444',
            textDecoration: location.pathname.includes(p.value) ? 'underline' : undefined,
            cursor: !location.pathname.includes(p.value) ? 'pointer' : undefined,
            '&:hover': {
              textDecoration: 'underline',
            }
          }}
          onClick={() => {
            navigate(p.value)
            onNavigate?.()
          }}
        >
          {p.label} 
        </Typography>
        </Grid>
      )))}
    </Grid>
    </MenuItem>
  )
}

const SIDEBAR_WIDTH = 400
const SidebarContent = ({ onNavigate } : { onNavigate?: () => void }) => {
  const session = useEnduserSession()
  const navigate = useNavigateToPage()
  const [loggingOut, setLoggingOut] = useState(false)
  const width = SIDEBAR_WIDTH

  const navigateToEcommercePortal = (baseURL: string) => () => (
    session
    .refresh_session()
    .then(({ authToken }) => window.location.href = `${baseURL}?token=${authToken}`)
    .catch(console.error)
  )

  return (
    <>
    <ResponsiveModal open={loggingOut} setOpen={setLoggingOut} maxWidth={400}>
    <Grid container justifyContent={"center"}>
    <Grid container direction="column" sx={{ maxWidth: 450, py: 5 }} spacing={1}>
      <Grid item>
        <Typography sx={{ mb: 3, fontSize: 20}}>
          Are you sure you want to logout?
        </Typography>
      </Grid>

      <Grid item sx={{ width: '100%' }} onClick={() => navigate(routes.logout)}>
        <Button fullWidth variant="contained">
          Yes, Logout
        </Button>
      </Grid>

      <Grid item sx={{ width: '100%' }}>
        <Button fullWidth variant="outlined" onClick={() => setLoggingOut(false)}>
          Cancel
        </Button>
      </Grid>
    </Grid>      
    </Grid>
    </ResponsiveModal>

    <Grid container direction="column" style={{ height: '100vh' }}>
      <Grid item>
        <Grid container alignItems="center" justifyContent="center"
          style={{ padding: 5, height: 125, marginTop: 50, marginBottom: 15 }}
        >
          <img alt="business logo" src={Logo} style={{ height: 100, maxWidth: width, padding: '20px' }} />
        </Grid> 

        <Grid container justifyContent={"center"} sx={{ mt: 5 }}>
        <MenuList
          variant="selectedMenu"
          sx={{
            flexGrow: 0,
            width,
            overflowX: 'none',
            overflowY: 'auto',
            maxWidth: 300,
          }}
        >
        <MobileNavigationItem path={'external_dashboard' as any} title="Home" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co')}/>
        <MobileNavigationItem path={'home'} title="Care Team" onClick={onNavigate}/>
        {/* <MobileNavigationItem path={'external_orders' as any} title="Orders" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/orders/')}/>
        <MobileNavigationItem path={'external_subscriptions' as any} title="Subscriptions" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/subscriptions/')}/> */}
        <MobileNavigationItem path={'content'} title="Resources" onClick={onNavigate}/>
        <MobileNavigationItem path={'events'} title="Appointments" onClick={onNavigate}/>
        <MobileNavigationItem path={'documents'} title="Labs" onClick={onNavigate}/>
        <MobileNavigationItem path={'treatment_plan' as any} title="Treatment Plan" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/treatment-plan')}/>
        <MobileNavigationItem path={'shop' as any} title="Shop" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/shop')}/>
        {/* <MobileNavigationItem path={'communications'} title="Chat" onClick={onNavigate}/> */}
        {/* <MobileNavigationItem path={'purchases'} title="Orders" onClick={onNavigate}/> */}
        {/* <MobileNavigationItem path={'settings'} title="Profile" onClick={onNavigate}/> */}

        <MenuItemForPage page={navigationInfo.logout} iconOnly={!open} onClick={() => setLoggingOut(true)} 
          style={{ marginTop: '25px' }} 
        />
        </MenuList>
        </Grid>
      </Grid>
    </Grid>
    </>
  )
}

const DisplayPictureMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      <IconButton
        id="demo-customized-button"
        aria-controls={drawerOpen ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={drawerOpen ? 'true' : undefined}
        onClick={() => setDrawerOpen(o => !o)}
      >        
        <Paper elevation={5} sx={{ borderRadius: '100%' }}>
          <DisplayPictureForSelf />
        </Paper>
      </IconButton>

      <Drawer anchor={'right'} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <SidebarContent onNavigate={() => setDrawerOpen(false)} />
      </Drawer>
    </>
  )
}

const MobileNavigationItem = ({ path, title, onClick } : { path: keyof Routes, title: string, onClick?: () => void }) => (
  <RouterLink to={onClick ? '#' : routes[path]} style={{ textDecoration: 'none', color: "#000000" }} onClick={onClick}>
  <MenuItem>
    <Typography 
      sx={{ 
        fontSize: 22,
        fontWeight: 'bold', opacity: 0.8,
        '&:hover': {
          opacity: 1,
        }
      }}
    >
      {title}
    </Typography>
  </MenuItem>
  </RouterLink>
)

const TopNavigationItem = ({ path, title, onClick } : { path: keyof Routes, title: string, onClick?: () => void }) => {
  const location = useLocation()

  const active = location.pathname.includes(routes[path])
  return (
    <RouterLink to={onClick ? '#' : routes[path]} style={{ textDecoration: 'none', color: "#000000" }} onClick={onClick}>
      <Typography 
        sx={{ 
          fontSize: 17,
          fontWeight: 'bold', opacity: 0.8,
          borderBottom: active ? '1.5px solid #000000aa' : undefined,
          '&:hover': {
            borderBottom: '2.5px solid black',
            opacity: 1,
          }
        }}
      >
        {title}
      </Typography>
    </RouterLink>
  )
}

const CartNavigationIcon = () => {
  const navigateToEcommercePortal = useNavigateToEcommercePortal()

  return (
    <IconButton onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/shop')}>
      <CartIcon color="primary" />
    </IconButton>
  )
}

const get_baseURL = (_baseURL: string) => (
  window.location.origin.includes("s3-website.us-east-2.amazonaws.com") 
    ? _baseURL.replace('https://portal.blokesandjoi.co', 'https://devportal.blokesandjoi.co')
    : _baseURL
)

const useNavigateToEcommercePortal = () => {
  const session = useEnduserSession()
  const navigateToEcommercePortal = (_baseURL: string) => () => {
    const baseURL = get_baseURL(_baseURL)

    session
    .refresh_session()
    .then(({ authToken }) => window.location.href = (`${baseURL}?token=${authToken}`))
    .catch(console.error)
  }

  return navigateToEcommercePortal
}

const TopNavigation = () => {
  const isMobile = useIsMobile()
  const homepageLink = useHomepageLink()

  const navigateToEcommercePortal = useNavigateToEcommercePortal()

  if (isMobile) {
    return (
      <Grid container alignItems="center" justifyContent={"space-between"} sx={{ py: 1.5 }}>
        <Grid item sx={{ ml: 2 }}>
        <a href={homepageLink}>
          <img alt="blokes and joi logo" src={Logo} style={{ height: 40 }} />
        </a>
        </Grid>

        <Grid item sx={{ mr: 2 }}>
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item><CartNavigationIcon /></Grid>
          <Grid item><DisplayPictureMenu /></Grid>
        </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container alignItems="center" justifyContent={"space-between"} sx={{ py: 1.5 }} wrap="nowrap">
      <Grid item sx={{ pl: 3 }}>
        <a href={homepageLink}>
        <img alt="blokes and joi logo" src={Logo} style={{ height: 40 }} />
        </a>
      </Grid>
      
      <Grid item>
      <Grid container alignItems="center" spacing={2.25}>
        <Grid item>
          <TopNavigationItem path={'external_dashboard' as any} title="Home" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co')}/>
        </Grid>

        <Grid item><TopNavigationItem path="home" title="Care Team" /></Grid>

        {/* <Grid item>
          <TopNavigationItem path={'external_orders' as any} title="Orders" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/orders/')}/>
        </Grid>

        <Grid item>
          <TopNavigationItem path={'external_subscriptions' as any} title="Subscriptions" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/subscriptions/')}/>
        </Grid> */}

        <Grid item><TopNavigationItem path="content" title="Resources" /></Grid>
        <Grid item><TopNavigationItem path="events" title="Appointments" /></Grid>
        <Grid item><TopNavigationItem path="documents" title="Labs" /></Grid>
        <Grid item><TopNavigationItem path={'treatment_plan' as any} title="Treatment Plan" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/treatment-plan')}/></Grid>
        <Grid item><TopNavigationItem path={'shop' as any} title="Shop" onClick={navigateToEcommercePortal('https://portal.blokesandjoi.co/shop')}/></Grid>
        {/* <Grid item><TopNavigationItem path="communications" title="Chat" /></Grid> */}
        {/* <Grid item><TopNavigationItem path="purchases" title="Orders" /></Grid> */}
        {/* <Grid item><TopNavigationItem path="settings" title="Profile" /></Grid> */}
        <Grid item><TopNavigationItem path="logout" title="Logout" /></Grid>
      </Grid>
      </Grid>

      <Grid item sx={{ pr: 3 }}>
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item><CartNavigationIcon /></Grid>
          <Grid item><DisplayPictureMenu /></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const RedirectToExternalDashboard = () => {
  const navigateToEcommercePortal = useNavigateToEcommercePortal()

  useEffect(() => navigateToEcommercePortal("https://portal.blokesandjoi.co"), [navigateToEcommercePortal])

  return (
    <Grid container alignItems='center' justifyContent={"center"} sx={{ mt: 5 }}>
      <CircularProgress size={100} thickness={2} />
    </Grid>
  )
}

const WithTopNavigation = ({ children } : { children: React.ReactNode }) => {
  const location = useLocation()

  const parentSx: SxProps = (
    location.pathname.includes('home')
      ? { maxWidth: 1200, width: '95%' }
      : { maxWidth: 800, width: '80%' }
  )

  return (
    <Grid container direction="column">
      <Grid item><TopNavigation /></Grid>
      <Grid item sx={{ mt: 3 }}>
      <Grid container justifyContent={"center"}>
        <Grid item sx={parentSx}>
          {children}
        </Grid>
      </Grid>
      </Grid>
    </Grid>
  )
}

export const AuthenticatedContainer = ({ children, hideNavigation } : HasChildren & { hideNavigation: boolean }) => {
  // const width = useWindowWidth()
  if (hideNavigation) return <>{children}</>
  return <WithTopNavigation>{children}</WithTopNavigation>
  // return (
  //   width < MOBILE_WIDTH_CUTOFF
  //     ? <WithMobileNavigation>{children}</WithMobileNavigation>
  //     : <WithSidebar>{children}</WithSidebar>  
  // )
}

const proximityToNow = (n: number) => Math.abs(n - Date.now()) 

export const WithVideoToast = ({ children } : { children: React.ReactNode }) => {
  const location = useLocation()
  const [eventsLoading] = useCalendarEvents()
  const [, { findById: findMeeting }] = useMeetings()
  const [closedMeeting, setClosedMeeting] = useState<string>('')
  const isMobile = useIsMobile()

  if (location.pathname.includes(routes.video)) return <>{children}</>
  if (!value_is_loaded(eventsLoading)) return <>{children}</>

  const liveEvent = (
    (
      [...eventsLoading.value]
      .sort((e1, e2) => proximityToNow(e1.startTimeInMS) - proximityToNow(e2.startTimeInMS))
    )
    .find(e => 
       e.enableVideoCall
    && e.meetingStatus === 'live'
    && Date.now() - (e.startTimeInMS) < (1000 * 60 * 60 * 2)
    )
  )

  const liveMeeting = findMeeting(liveEvent?.meetingId ?? '')

  const handleClose = () => setClosedMeeting(liveMeeting?.id ?? liveEvent?.id ?? '')

  return (
    <>
    {(liveEvent || liveMeeting) && (closedMeeting !== liveEvent?.id && closedMeeting !== liveMeeting?.id) && (
      <Alert onClose={handleClose} severity="info" sx={{ 
        position: 'fixed',
        bottom: isMobile ? 75 : 15,
        right: 15,
        zIndex: 1000,
       }}>
        Join your upcoming call 
        - <Link to={routes.video} query={{ 
            meetingId: liveMeeting?.id ?? '', 
            calendarEventId: liveEvent?.id ?? '',
          }}>
            here
          </Link>
      </Alert>
    )}
    {children}
    </>
  )
}