import React, { useEffect } from "react"
import { Grid, Typography } from "@mui/material"
import { LoadingButton, Modal, useCalendarEvents, useMeetings } from "@tellescope/react-components"
import { CalendarEvent, Meeting } from "@tellescope/types-client"
import { ControlBar, LocalPreview, useCurrentCallContext, useJoinMeeting, VideoTileGrid } from "@tellescope/video-chat"
import { Navigate, useSearchParams } from "react-router-dom"
import { routes, useNavigateToPage } from "../definitions/routes"
import { useFullHeight } from "../components/navigation"

interface CallHandlers {
  onLeave?: () => void;
  onStart?: () => void;
}
interface VideoCallProps extends CallHandlers {}
export const VideoCallView = ({ ...props } : VideoCallProps) => (
  <Modal open={true} setOpen={() => undefined} 
    style={{ width: '100vw', height: '100vh' }}
  >
  <Grid container direction="column" id="video-container">
    <Grid container sx={{ height: `calc(100% - 70px)` }}>
      <VideoTileGrid />
    </Grid>
    
    <ControlBar {...props} 
      style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'black' }}
    />
  </Grid>
  </Modal>
)

export const WaitingRoom = ({ calendarEvent, meeting } : { calendarEvent?: CalendarEvent, meeting?: Meeting }) => {
  const navigate = useNavigateToPage()
  const height = useFullHeight()
  const [, { findById: findEvent }] = useCalendarEvents()
  const [, { findById: findMeeting }] = useMeetings()
  const { joinMeeting } = useJoinMeeting()

  // poll to check for started meeting
  useEffect(() => { 
    const t = setInterval(() => {
      if (!calendarEvent?.id) return

      const event = findEvent(calendarEvent.id, { reload: true })
      findMeeting(event?.meetingId ?? '', { reload: true }) // reloads tsMeeting
    }, 2500)
    
    return () => { clearInterval(t) }
  }, [calendarEvent, findMeeting, findEvent])

  // when there's a Zoom call, don't load the built-in video conferencing or show preview
  useEffect(() => {
    if (calendarEvent?.videoIntegration && calendarEvent.videoIntegration !== 'No Integration') {
      navigate(routes.events, { id: calendarEvent.id })
    }
  }, [calendarEvent, navigate])

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center"
      style={{ minHeight: height, width: '100%'}}
    >
      <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
        {calendarEvent?.title}
      </Typography>

      <Typography style={{ marginBottom: 15 }}>
        Waiting Room
      </Typography>

      {/* don't show and prompt for permissions if using external video url */}
      {calendarEvent && (!calendarEvent.videoIntegration || calendarEvent.videoIntegration === 'No Integration') 
        && <LocalPreview />
      }

      <LoadingButton variant="contained" disabled={!meeting || meeting.status === 'ended'} 
        onClick={() => joinMeeting(meeting!)} 
        submitText="Join Meeting" submittingText="Join"
        style={{ marginTop: 15 }} 
      />

      <Typography style={{ marginTop: 5 }}>
        {!meeting 
          ? "Waiting for the host to start the meeting" 
          : meeting?.status === 'ended'
           ? "This meeting has been ended by the host"
           : ''
        }

      </Typography>
    </Grid>
  )
}

export const VideoCall = () => {
  const navigate = useNavigateToPage()
  const [search] = useSearchParams()

  const [, { findById: findMeeting }] = useMeetings()
  const [, { findById: findEvent }] = useCalendarEvents()

  const { meeting } = useCurrentCallContext()

  const calendarEventId = search.get('calendarEventId')
  const meetingId = search.get('meetingId')

  const event = findEvent(calendarEventId ?? '')
  const tsMeeting = findMeeting(meetingId ?? event?.meetingId ?? '') 

  if (meeting) {
    return (
      <VideoCallView onLeave={() => { navigate(routes.home) }} />
    )
  }
  if (event === null) {
    return <Typography>This event no longer exists</Typography>
  }
  if (calendarEventId || meetingId) {
    return (
      <WaitingRoom calendarEvent={event} meeting={tsMeeting ?? undefined} />
    )
  }
  return <Navigate replace to={routes.home}/> 
}