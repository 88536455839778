import React from "react"
import {
  Grid, 
  Typography,
} from "@mui/material"

import { LabeledIconButton, useEnduserSessionContext } from "@tellescope/react-components"
import { EmailAndPasswordInput } from "../components/inputs"
import { routes, useNavigateToPage } from "../definitions/routes"
import { GoBackArrowIcon } from "../components/icons"
import { TERMS_VERSION } from "../components/agreements"
import Logo from "../img/logo.png"

export const Register = () => {
  const navigate = useNavigateToPage()
  const { enduserSession, updateLocalSessionInfo } = useEnduserSessionContext()

  return (
    <Grid container justifyContent="center">
    <Grid container direction="column" justifyContent={"center"} wrap="nowrap"
      sx={{ height: '100vh', py: 4, maxWidth: 600 }}
    >
      <Grid item>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item alignSelf="flex-start">
          <LabeledIconButton Icon={GoBackArrowIcon} label="Go Back" color="default"
            style={{ marginLeft: 5 }}
            onClick={() => navigate(routes.landing)}
          />
        </Grid>

        <Grid item>
        <Grid container justifyContent="center">
          <img src={Logo} alt="business logo" style={{ maxHeight: 50 }} />
        </Grid>
        </Grid>

        <Grid item>
          <Typography color="primary" sx={{ fontSize: 40, px: 2, textAlign: 'center' }}>
            Getting Started
          </Typography>
        </Grid>

        <Grid item>
          <Typography sx={{ fontSize: 17, textAlign: 'center', mb: 1 }}>
            Let's create your account.
          </Typography>
        </Grid>

      </Grid>
      </Grid>

      <Grid item sx={{ px: 2, mt: '15vh' }}>
        <EmailAndPasswordInput submitText="Register" submittingText="Working..."
          requireTerms
          onSubmit={async ({ email, password }) => {
            try {
              await enduserSession.register({ 
                email, password, 
                termsSigned: new Date(),
                termsVersion: TERMS_VERSION,
                emailConsent: true,
              })
              const { authToken, enduser } = await enduserSession.authenticate(email, password)

              updateLocalSessionInfo(enduser, authToken)
            } catch(err: any) {
              return err?.message ?? 'An unknown error occurred'
            }
          }}
          alternateButtonText="Log in to your account"
          onClickAlernateButton={() => navigate(routes.login)}
        /> 
      </Grid>
    </Grid>
    </Grid>
  )
}