import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import {
  Badge,
  Divider,
  Grid, 
  LinearProgress, 
  Paper, 
  Typography, 
} from "@mui/material"
import { 
  // DeleteWithConfimrationIcon,
  ScrollingList, 
  useEnduserSession, 
  useFiles, 
  useFormResponses, 
  useResolvedSession, 
  useTellescopeForm,
  useDownloadSecureFile,
  value_is_loaded,
  TellescopeSingleQuestionFlow,
  elapsed_time_display_string,
  LoadingLinear,
  useModalIconButton,
  IconModal,
  FileBlob,
  useFileUpload,
  FileDropzone,
  LoadingButton,
  LabeledIconButton,
  ResponseAnswer, 
} from "@tellescope/react-components"
import { Enduser, File, Form, FormField, FormResponse } from "@tellescope/types-client"
import { getPublicFileURL, mm_dd_yyyy } from "@tellescope/utilities"
import { ResponsiveModal } from "../components/layout"
import { routes, useNavigateToPage } from "../definitions/routes"
import { useParams } from "react-router-dom"
import { AddCircleIcon, FileIcon, FormIcon, FormOpenIcon, WithCircularBackground } from "../definitions/icons"
import { DASHBOARD_CHILD_MARGIN } from "../definitions/constants"
import { useFullHeight } from "../components/navigation"
import { ViewMenuIconButton } from "../components/ViewMenuIconButton"
import { TitleWithFirstName } from "../components/titles"
import { StringSelector } from "../components/inputs"
import { useFileViewer } from "../components/files"
import CloseIcon from '@mui/icons-material/Close';
import { VITAL_TITLE, VITAL_UNITS } from "@tellescope/constants"

// simplified and more mobile friendly than the Library implementation
interface FormResponse_T {
  enduser?: Enduser,
  onClose?: () => void,
  hideHeader?: boolean,
  response: FormResponse,
  id?: string,
}
// this should use all vanilla React / inline styles to ensure printing is consistent
export const FormResponseView = ({ enduser, onClose, hideHeader, response, id, printing } : FormResponse_T & { printing ?: boolean }) => {
  if (response.responses === undefined || response.responses.length === 0) {
    return <Typography>Awaiting Response</Typography> 
  }

  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', minWidth: 250, maxWidth: 650 }} id={id}>
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {!hideHeader && 
          <>
          <h2 style={{
            fontSize: 20,
            fontWeight: 'bold',
          }}> 
            {response.formTitle}
          </h2>
          </>
        }

        {onClose 
          ? <LabeledIconButton Icon={CloseIcon} label="Close" onClick={onClose} />
          : <Grid />
        }
      </div>

      <Divider flexItem style={{ marginTop: 2, marginBottom: 12 }} />

      <div style={{ flexDirection: "column", display: 'flex', flex: 1 }}>
      {response.responses.map((r, i) => (
          <div key={i} style={{ marginBottom: 12 }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: "row", justifyContent: 'space-between', flexWrap: 'nowrap' }}>
              {r.fieldTitle && 
                <div style={{ }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {r.fieldTitle}
                  </Typography>
                </div>
              }
            </div>

            {r.fieldDescription
              ? (
                <Typography style={{  }}>
                  {r.fieldDescription}
                </Typography>
              ): r.fieldHtmlDescription
                ? (
                  <div dangerouslySetInnerHTML={{
                    __html: r.fieldHtmlDescription
                  }} />
                )
                : null
            } 

            <ResponseAnswer answer={r.answer} />
          </div>
        )
      )}
      </div>
    </div>
  )
}

export const FormResponsePreview = ({ item: response } : { item: FormResponse }) => {
  const navigate = useNavigateToPage()
  const completed = !!response.submittedAt
  return (
    <Grid container alignItems="center" wrap="nowrap"
      onClick={() => {
        if (completed) return
        navigate(routes.forms, { id: response.accessCode })
      }}
    >
      <Grid item>
        {completed
          ? <FormIcon color="success" />
          : <FormOpenIcon color="primary" />
        }
      </Grid>

      <Grid item direction="column" sx={{ ml: 1 }}>
        <Typography color="primary" style={{ 
          fontWeight: 'bold', 
          fontSize: 20,
        }}>
          {response.formTitle}
          {completed && <Typography sx={{ fontSize: 14 }}>Submitted {elapsed_time_display_string(new Date(response.submittedAt!))}</Typography>}
        </Typography>
      </Grid>
    </Grid>
  )
}

export const FormForLoadedData = ({ accessCode, response, fields, form } : { accessCode: string, response: FormResponse, form: Form, fields: FormField[] }) => {
  const navigate = useNavigateToPage()
  const formProps = useTellescopeForm({
    ...response,
    accessCode,
    fields,
  })

  return (
    <Grid container justifyContent="center">
    <Grid container direction="column" sx={{
      maxWidth: 600,
      p: 2,
    }}>
      <Grid item sx={{ my: 2 }}>
      <Grid container direction="column" alignItems="center">
        <Grid item sx={{ width: 38, height: 38, mb: 2 }}>
        <WithCircularBackground>
          <FormIcon />
        </WithCircularBackground>
        </Grid>

        <Typography sx={{ fontSize: 24, fontWeight: 'bold', mb: 2 }}>
          {form.title}
        </Typography>

        {form.description && 
          <Typography sx={{ fontSize: 16, opacity: 0.7, textAlign: 'center', mb: 2 }}>
            {form.description}
          </Typography>
        }

        <Divider flexItem sx={{ my: 2 }} />
      </Grid>
      </Grid>

      <TellescopeSingleQuestionFlow
        {...formProps}
        onSuccess={() => navigate(routes.documents)} 
        inputStyle={{
          marginBottom: '15px'
        }}
      />
    </Grid>
    </Grid>
  )
}

export const SubmitForm = () => {
  const session = useEnduserSession()
  const navigate = useNavigateToPage()
  
  const accessCode = useParams().accessCode!

  const [info, setInfo] = useState<undefined | { response: FormResponse, fields: FormField[], form: Form }>(undefined)
  const fetchRef = useRef(false)

  useEffect(() => {
    if (fetchRef.current) return
    fetchRef.current = true

    session.api.form_responses.info_for_access_code({ accessCode })
    .then(setInfo)
    .catch((err: any) => {
      alert(err.message)
      navigate(routes.documents) 
    })
  }, [fetchRef, session, accessCode, navigate])

  if (!info) return <LinearProgress />
  return (
    <FormForLoadedData accessCode={accessCode} {...info} />
  )
}

export const ResponseCardContent = ({ response } : { response: FormResponse }) => {
  const navigate = useNavigateToPage()
  const session = useEnduserSession()
  const [open, setOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [, { updateElement: updateResponse }] = useFormResponses()

  return (
    <>
    <ResponsiveModal open={!!open} setOpen={setOpen} maxWidth={'750px'}>
    <Grid container justifyContent={"center"}>
      <FormResponseView enduser={session.userInfo} onClose={() => setOpen(false)} 
        // when a specific field is sharedWithEnduser, only show sharedWithEnduser fields
        response={
          response?.responses?.find(r => r.sharedWithEnduser === true)
            ? {
              ...response, responses: response.responses.filter(r => r.sharedWithEnduser)
            }
            : response
        }
      />
    </Grid>
    </ResponsiveModal>

    <Grid container justifyContent="space-between" wrap="nowrap"
      sx={{ cursor: !response.submittedAt ? 'pointer' : '' }}
      onClick={() => {
        if (response.submittedAt) return
        navigate(routes.forms, { id: response.accessCode })
      }}
    >
      <Grid item>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item sx={{ mr: 1 }}>
          <WithCircularBackground>
            {response.submittedAt
              ? <FormIcon />
              : (
                <Badge variant="dot" badgeContent={1} color="primary">
                  <FormIcon color="primary" />
                </Badge>
              )
            }
          </WithCircularBackground>
        </Grid>

        <Grid container direction="column">
          <Typography style={{ fontSize: 18 }}>
            {response.formTitle}
          </Typography>

          {response.submittedAt &&
            <Typography style={{ fontSize: 14, opacity: 0.8 }}>
              Uploaded {mm_dd_yyyy(new Date(response.submittedAt))}
            </Typography>
          }
        </Grid>
      </Grid>
      </Grid>

      <Grid item>
        {response.submittedAt &&
          <ViewMenuIconButton open={menuOpen} setOpen={setMenuOpen}
          entries={[
            {
              label: "View Responses",
              onClick: () => setOpen(true),
            },
            {
              label: "Hide",
              onClick: () => (
                updateResponse(response.id, { hideFromEnduserPortal: true }).catch(console.error)
              ),
            }
          ]}
        />
      }
      </Grid>
    </Grid>
    </>
  )
}

export const FileCardContent = ({ file } : { file: File }) => {
  const session = useEnduserSession()
  const [open, setOpen] = useState(false)
  const { downloadFile } = useDownloadSecureFile({})
  const [, { updateElement: updateFile }] = useFiles()

  const { setFile } = useFileViewer()

  const handleDownloadFile = async () => {
    // pull old files directly from Vital, as they were blank on import by mistake
    if (
      file.externalId && file.source === VITAL_TITLE && !file.name?.startsWith('fixed_')
      && new Date(file.createdAt).getTime() < 1719939022635
    ) {
      try {
        const url = (
          await axios.get(
            session.host + '/v1/integrations/proxy-read', 
            { 
              params: { integration: VITAL_TITLE, type: 'pdf', id: file.externalId, ...session.getAuthInfo(true)  }, 
              headers: {...session.config.headers }
            })
        ).data
        
        window.open(url);
      } catch(err) {
        console.error(err)
      }
      
      return
    }
    if (!file.publicRead) {
      return (
        downloadFile(file.secureName)
        .then(url => 
          setFile({ name: file.name, url })
          // window.open(url, "_blank")
        )
        .catch(err => alert(err?.message))
      )
    }
    setFile({ name: file.name, url: getPublicFileURL({ businessId: file.businessId, name: file.secureName }) })
    // window.open(
    //   getPublicFileURL({ businessId: file.businessId, name: file.secureName }),
    //   "_blank"
    // )
  }

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item sx={{ mr: 1 }}>
        <WithCircularBackground><FileIcon /></WithCircularBackground>
      </Grid>

      <Grid item sx={{
        width: {
          xs: 175,
          sm: 300,
          md: 400,
        },
      }}>
        <Typography noWrap onClick={handleDownloadFile}
          sx={{ 
            fontSize: 18, 
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover': { color: 'primary.main' }
          }}
        >
          {file.name}
        </Typography>

        <Typography style={{ fontSize: 14, opacity: 0.8 }}>
          Uploaded {mm_dd_yyyy(new Date(file.timestamp || file.createdAt || file.updatedAt))}
        </Typography>
      </Grid>

      <Grid item alignItems="center" justifyContent="flex-end" sx={{
        ml: 'auto',
      }}>
        <ViewMenuIconButton open={open} setOpen={setOpen}
          entries={[
            { label: 'Download', onClick: handleDownloadFile },
            {
              label: "Hide",
              onClick: () => (
                updateFile(file.id, { hideFromEnduserPortal: true }).catch(console.error)
              ),
              hidden: file.pushedToClientPortal, // pushed to all endusers, can't hide as individual
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export const FileUploader = ({
  placeholder="Click to select a file, or drag and drop",
  publicName,
  publicRead,
  onSuccess,
} : { 
  publicName?: string,
  publicRead?: boolean,
  placeholder?: string,
  style?: React.CSSProperties,
  onSuccess?: (result: { secureName: string }) => void,
  onRemove?: () => any,
}) => {
  const [file, setFile] = useState<FileBlob | undefined>()
  const { handleUpload, uploading } = useFileUpload({ publicName, publicRead: publicRead || !!publicName })

  return (
    <Grid container direction="column" sx={{ width: 280 }}>
      <FileDropzone file={file} onChange={setFile}
        label={placeholder}
        dropzoneStyle={{
          border: '1px solid black',
          borderRadius: 5,
          width: 280,
          height: 200,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />

      <LoadingButton submitting={uploading} disabled={!file}
        submitText="Upload File"
        submittingText="Uploading..."
        onClick={async () => {
          if (!file) return
          
          const result = (
            await handleUpload({
              name: file.name,
              type: file.type,
              size: file.size,
            }, file)
          )
          onSuccess?.(result)
          setFile(undefined)
        }}
      />
    </Grid>
  )
}

export const UploadFileIconButton = () => {
  const iconProps = useModalIconButton({ Icon: AddCircleIcon, label: "Add File" })

  return (
    <IconModal {...iconProps} ModalComponent={ResponsiveModal}
      style={{ maxWidth: 500, minHeight: 400 }}
    >
    <Grid container direction="column" alignItems={"center"} justifyContent="center" spacing={1}>
      <Grid item>
        <Typography sx={{ fontSize: 20 }}>
          Upload a new file
        </Typography>
      </Grid>

      <Grid item>
        <FileUploader onSuccess={() => iconProps.setOpen(false)} />
      </Grid>
    </Grid>  
    </IconModal>
  )
}

export const FilesAndFormsList = () => {
  const session = useResolvedSession()
  const fullHeight = useFullHeight()
  const [, { filtered: filteredFiles, doneLoading: doneLoadingFiles, loadMore: loadMoreFiles } ] = useFiles()
  const filesLoading = filteredFiles(f => !f.hideFromEnduserPortal && f.enduserId === session.userInfo.id && (f.type === 'application/pdf' || f.name.endsWith('.pdf')))
  const pushedFilesLoading = filteredFiles(f => !!f.pushedToClientPortal)

  const [selectedFiles, setSelectedFiles] = useState<'All Files' | 'Your Uploaded Files' | 'Shared Files'>('All Files')

  const [, { filtered: filteredResponses, doneLoading: doneLoadingResponses, loadMore: loadMoreResponses }] = useFormResponses({ }) // loads unsubmitted forms as well
  const formResponsesLoading = filteredResponses(f => 
    !f.hideFromEnduserPortal
    && (
      f.submittedBy === session.userInfo.id  // form submitted by enduser
    || (!f.submittedBy && !f.draftSavedAt) // form outstanding (and not saved draft by user)
    || (!!f.responses.find(r => r.sharedWithEnduser === true))
    )
  )
 
  // if using files and forms in same list
  // const doneLoading = useCallback(() => 
  //   doneLoadingResponses() && doneLoadingFiles(), 
  //   [doneLoadingFiles, doneLoadingResponses]
  // )
  // const loadMore = useCallback(async () => {
  //   if (!doneLoadingFiles()) { loadMoreFiles().catch(console.error) };
  //   if (!doneLoadingResponses()) { loadMoreResponses().catch(console.error) };
  // }, [doneLoadingFiles, doneLoadingResponses, loadMoreFiles, loadMoreResponses])

  const outstandingForms = value_is_loaded(formResponsesLoading) ? formResponsesLoading.value.filter(f => !f.submittedAt) : [] 

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item sx={{ my: 3, textAlign: 'center' }}>
        <TitleWithFirstName ending="Documents" />
      </Grid>

      <Grid item sx={{ mb: 4 }}>
        <Typography>
          Your lab results and other uploaded documents appear here
        </Typography>
      </Grid>

      <LoadingLinear data={pushedFilesLoading} render={files => 
        files.length === 0 
          ? <></>
          : (
            <ScrollingList items={files} 
              doneLoading={doneLoadingFiles} loadMore={loadMoreFiles}
              maxHeight={`calc(${fullHeight} / 2)`} 
              title="Shared Files" emptyText=""
              titleStyle={{ paddingLeft: DASHBOARD_CHILD_MARGIN }}
              itemContainerStyle={{
                padding: DASHBOARD_CHILD_MARGIN,
                paddingTop: 2,
              }}
              Item={({ item }) => (
                <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
                  <FileCardContent file={item} />
                </Paper>
              )}
            />
          )
        } 
      />
      
      {outstandingForms.length > 0 &&
        <Grid container sx={{ mb: 4 }}>
        <ScrollingList items={outstandingForms} 
          doneLoading={doneLoadingResponses} loadMore={loadMoreResponses}
          maxHeight={'50vh'} 
          title="Outstanding Forms" emptyText=""
          titleStyle={{
            paddingLeft: DASHBOARD_CHILD_MARGIN,
            paddingRight: DASHBOARD_CHILD_MARGIN,
            marginBottom: 0,
          }}
          itemContainerStyle={{
            paddingTop: 2,
            paddingLeft: DASHBOARD_CHILD_MARGIN,
            paddingRight: DASHBOARD_CHILD_MARGIN,
          }}
          Item={({ item }) => (
            <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
              <ResponseCardContent response={item} />
            </Paper>
          )}
        />
        </Grid>
      }

      {/* <LoadingLinear data={formResponsesLoading} render={responses => (
        <ScrollingList items={responses} 
          doneLoading={doneLoadingResponses} loadMore={loadMoreResponses}
          maxHeight={`calc(${fullHeight} / 2)`} 
          title="Your Forms" emptyText="Your forms will appear here"
          titleStyle={{ paddingLeft: DASHBOARD_CHILD_MARGIN }}
          itemContainerStyle={{
            padding: DASHBOARD_CHILD_MARGIN,
            paddingTop: 2,
          }}
          Item={({ item }) => (
            outstandingForms.find(f => f.id === item.id) 
              ? null 
              : (
                <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
                  <ResponseCardContent response={item} />
                </Paper>
              )
          )}
        />
      )} /> */}

      <LoadingLinear data={filesLoading} render={files => (
        <ScrollingList 
          items={files.filter(f => 
            selectedFiles === 'Shared Files'
              ? f.creator !== session.userInfo.id
          : selectedFiles === 'Your Uploaded Files'
              ? f.creator === session.userInfo.id
              : true
            
          )} 
          doneLoading={doneLoadingFiles} loadMore={loadMoreFiles}
          maxHeight={`calc(${fullHeight} / 2)`} 
          emptyText="No documents found"
          TitleComponent={() => (
            <Grid container alignItems="center" sx={{ mb: 0.5 }}>
              <StringSelector value={selectedFiles} size="small" 
                style={{ marginLeft: DASHBOARD_CHILD_MARGIN, minWidth: 200 }}
                selectStyle={{ fontWeight: 'bold', fontSize: 18 }}
                itemStyle={{ fontWeight: 'bold' }}
                onChange={s => setSelectedFiles(s)}
                options={['All Files', 'Shared Files', 'Your Uploaded Files']}
              />

              <Grid item sx={{ ml: 0.5, mb: 0.3 }}>
                <UploadFileIconButton />
              </Grid>
            </Grid>
          )}
          titleStyle={{ paddingLeft: DASHBOARD_CHILD_MARGIN }}
          itemContainerStyle={{
            padding: DASHBOARD_CHILD_MARGIN,
            paddingTop: 2,
          }}
          Item={({ item }) => (
            <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
              <FileCardContent file={item} />
            </Paper>
          )}
        />
      )} />
  </Grid>
  )
}

export const Documents = () => <FilesAndFormsList />
// (
//   <Grid container style={dashboardChildStyles}>
//   <TabNavigation tabs={[
//     { label: "Form Responses", component: <FormResponses /> },
//     { label: "Files", component: <Files /> },
//   ]}/>
//   </Grid>
// )