// MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS

import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { Form, FormField, FormResponse } from "@tellescope/types-client"
import { TellescopeForm, useEnduserSession, useTellescopeForm } from "@tellescope/react-components"

// MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS
const FormForLoadedData = ({
  accessCode,
  automationStepId,
  enduserId='', 
  formResponseId,
  fields,
  thanksMessage,
  htmlThanksMessage,
  alreadySubmitted,
  form,
  hideBg,
} : { 
  accessCode: string,
  formResponseId: string,
  automationStepId?: string,
  enduserId?: string,
  form: Form
  fields: FormField[],
  thanksMessage?: string,
  htmlThanksMessage?: string,
  alreadySubmitted?: boolean,
  hideBg?: boolean,
}) => {
  const formProps = useTellescopeForm({
    accessCode,
    formResponseId,
    automationStepId,
    enduserId, // library autofills in enduserSession
    fields,
    formTitle: form.displayTitle || form.title,
    ga4measurementId: form.ga4measurementId,
  })

  const [submitted, setSubmitted] = useState(alreadySubmitted || false)

  // MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS

  return (
    <TellescopeForm 
      {...formProps} submitted={submitted} 
      hideBg={hideBg}
      thanksMessage={thanksMessage} 
      htmlThanksMessage={htmlThanksMessage} 
      onSuccess={() => setSubmitted(true)} 
    />
  )
}

// MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS
const FormDisplay = () => {
  const q = new URLSearchParams(window.location.search)
  const formId = q.get('f')!
  const accessCode = q.get('a')!
  const automationStepId = q.get('stepId') ?? undefined
  const hideBg = q.get('hideBg') ?? undefined
  const session = useEnduserSession()
  
  const [form, setForm] = useState(undefined as Form | undefined | null)
  const [fields, setFields] = useState<FormField[]>([])
  const [formResponseInfo, setFormResponseInfo] = useState(undefined as FormResponse | undefined | null)

  useEffect(() => {
    if (!formId) return;
    if (!accessCode) return;
    
    session.api.form_responses.info_for_access_code({ accessCode }) 
    .then(({ response, form, fields }) => {
      setForm({ ...form, id: form.id } as any as Form)
      setFields(fields)
      if (response) { 
        setFormResponseInfo(response)
      }
    })
    .catch((err: string) => {
      console.error(err)
      setFormResponseInfo(null)
    })
  }, [accessCode, formId, session])

  if (formResponseInfo === null) {
    return (
      <div>This form is no longer available</div>
    )
  }

  if (!(form && fields && formResponseInfo)) return null

  // MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS

  const alreadySubmitted = (formResponseInfo.responses?.length ?? 0) > 0
  return (
    <Grid container wrap="nowrap" sx={{ minHeight: '100vh' }}>
    <FormForLoadedData alreadySubmitted={alreadySubmitted}
      form={form}
      formResponseId={formResponseInfo.id}
      htmlThanksMessage={form.htmlThanksMessage}
      thanksMessage={alreadySubmitted 
        ? "This form has already been submitted"
        : form.thanksMessage
      }
      hideBg={!!hideBg}
      accessCode={accessCode} automationStepId={automationStepId} fields={fields} 
    />
    </Grid>
  )
}


export default FormDisplay