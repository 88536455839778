import React from "react"
import { Button, Divider, Grid, Typography } from "@mui/material"
import { routes, useNavigateToPage } from "../definitions/routes"
import Logo from "../img/logo.png"

export const LandingPage = () => {
  const navigate = useNavigateToPage()

  return (
    <Grid container justifyContent="center">
    <Grid container alignItems="center" justifyContent="center" direction="column" wrap="nowrap"
      sx={{ 
        height: '100vh', py: 8, maxWidth: 600,
      }}
    >
      <Grid item>
      <Grid container direction="column" alignItems="center">
        <Grid item sx={{ textAlign: 'center'}}>
          <img src={Logo} alt="business logo" style={{ maxHeight: 50 }} />
        </Grid>

        <Grid item sx={{ py: 4, px: 4, textAlign: 'center' }}>
          <Typography color="primary" sx={{ fontSize: 28 }}>
            Your Portal
          </Typography>
        </Grid>
      </Grid>
      </Grid>

      <Grid item sx={{ 
        width: '100%', px: 3, 
        mt: '10vh'
      }}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
        <Button fullWidth variant="contained" sx={{ borderRadius: 15, textTransform: 'none'  }}
          onClick={() => navigate(routes.login)}
        >
          Log in
        </Button>
        </Grid>

        <Grid item>
          <Divider flexItem>
            <Typography sx={{ opacity: 0.5 }}>or</Typography>
          </Divider>
        </Grid>
        
        <Grid item sx={{ width: '100%' }}>
        <Button variant="outlined" fullWidth sx={{ borderRadius: 15, textTransform: 'none', fontWeight: 'bold' }}
          onClick={() => navigate(routes.register)}
        >
          Sign up for an account
        </Button>
        </Grid>
      </Grid>
      </Grid>
    </Grid>
    </Grid>
  )
}