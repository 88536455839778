import React from "react"
import { SvgIcon, SvgIconProps } from "@mui/material"

export {
  MenuOpen as CloseMenuIcon,
  Article as ForumIcon,
  ChevronLeft as GoBackIcon,
  ExitToApp as LogoutIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Folder as DocumentIcon,
  Groups as CommunityIcon,
} from "@mui/icons-material"

export const buildCustomIcon = (e: React.ReactNode) => (props?: SvgIconProps) => <SvgIcon {...props}>{e}</SvgIcon>

export const InfoIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M12 15V12M12 9V8.97656M19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const AlertIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" />
    <path stroke="currentColor" d="M3.62207 17.5789C3.62283 18.3519 3.93019 19.0934 4.47694 19.64C5.02348 20.1867 5.76471 20.4941 6.53799 20.4948H18.0819C19.1218 20.4954 20.0831 19.9422 20.6052 19.0431C21.1274 18.1439 21.1312 17.0347 20.6151 16.1319L14.5365 5.49746C14.2356 4.97231 13.7589 4.57044 13.1903 4.3627C12.6218 4.15514 11.9984 4.15514 11.4299 4.3627C10.8613 4.57045 10.3847 4.97231 10.0837 5.49746L4.00804 16.1319C3.75597 16.5726 3.62264 17.0712 3.62207 17.5789ZM5.41928 16.9409L11.4955 6.30647C11.6049 6.11368 11.7794 5.96597 11.9875 5.88964C12.1958 5.81332 12.4242 5.81332 12.6325 5.88964C12.8406 5.96596 13.0151 6.11368 13.1245 6.30647L19.2007 16.9409C19.4281 17.3394 19.4262 17.8289 19.1958 18.2255C18.9651 18.6222 18.5409 18.8661 18.082 18.8658H6.53806C6.07919 18.8661 5.65497 18.6222 5.4243 18.2255C5.19382 17.8289 5.19183 17.3394 5.41928 16.9409Z" fill="black"/>
    <path stroke="currentColor" d="M11.4951 9.90625H13.1242V14.2503H11.4951V9.90625Z" fill="black"/>
    <path stroke="currentColor" d="M13.1242 16.422C13.1242 16.8717 12.7594 17.2365 12.3097 17.2365C11.8599 17.2365 11.4951 16.8718 11.4951 16.422C11.4951 15.9722 11.8599 15.6074 12.3097 15.6074C12.7594 15.6074 13.1242 15.9722 13.1242 16.422Z" fill="black"/>
  </svg>
)

export const AttachmentIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" />
    <path d="M8.40242 13.9646L6.40242 10.5005C5.574 9.06562 6.06562 7.23085 7.5005 6.40242C8.93538 5.574 10.7701 6.06562 11.5986 7.5005L12.9423 9.82794M14.6967 6.86653L17.4467 11.6297C18.6893 13.782 17.9519 16.5341 15.7995 17.7768C13.6472 19.0194 10.8951 18.282 9.65243 16.1297L8.46493 14.0729M8.94525 8.94617L12.2481 14.6333C12.664 15.3492 13.5808 15.5937 14.2979 15.1797C15.0162 14.7649 15.2624 13.8464 14.8476 13.128L12.5255 9.10589" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
)

export const GoBackArrowIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M19 12L5 12M5 12L11 19M5 12L11 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const CalendarIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" />
    <path d="M9 6H8.2C7.0799 6 6.51984 6 6.09202 6.21799C5.71569 6.40973 5.40973 6.71569 5.21799 7.09202C5 7.51984 5 8.0799 5 9.2V11M9 6H15M9 6V5M9 6V8M15 6H15.8C16.9201 6 17.4802 6 17.908 6.21799C18.2843 6.40973 18.5903 6.71569 18.782 7.09202C19 7.51984 19 8.0799 19 9.2V11M15 6V5M15 6V8M19 11V15.8C19 16.9201 19 17.4802 18.782 17.908C18.5903 18.2843 18.2843 18.5903 17.908 18.782C17.4802 19 16.9201 19 15.8 19H8.2C7.0799 19 6.51984 19 6.09202 18.782C5.71569 18.5903 5.40973 18.2843 5.21799 17.908C5 17.4802 5 16.9201 5 15.8V11M19 11H5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg>
)
export const EventsIcon = CalendarIcon

export const CarePlanIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M17.9447 7.04697C16.5376 5.65101 14.2563 5.65101 12.8493 7.04697L12 8L11.1507 7.04699C9.74368 5.65102 7.46237 5.65102 6.0553 7.04699C4.64823 8.44295 4.64823 10.7063 6.0553 12.1022L12 18L17.9447 12.1022C19.3518 10.7062 19.3518 8.44294 17.9447 7.04697Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  </svg>
)

export const ContactIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M5 9L12 12L19 9M8.2 18H15.8C16.9201 18 17.4802 18 17.908 17.782C18.2843 17.5903 18.5903 17.2843 18.782 16.908C19 16.4802 19 15.9201 19 14.8V9.2C19 8.0799 19 7.51984 18.782 7.09202C18.5903 6.71569 18.2843 6.40973 17.908 6.21799C17.4802 6 16.9201 6 15.8 6H8.2C7.0799 6 6.51984 6 6.09202 6.21799C5.71569 6.40973 5.40973 6.71569 5.21799 7.09202C5 7.51984 5 8.07989 5 9.2V14.8C5 15.9201 5 16.4802 5.21799 16.908C5.40973 17.2843 5.71569 17.5903 6.09202 17.782C6.51984 18 7.07989 18 8.2 18Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  </svg> 
)

export const ContentIcon = buildCustomIcon( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M12 7.05477V18M12 7.05477C11.0916 6.40149 9.85838 6 8.5 6C7.14162 6 5.90836 6.40149 5 7.05477V18C5.90836 17.3467 7.14162 16.9452 8.5 16.9452C9.85838 16.9452 11.0916 17.3467 12 18M12 7.05477C12.9084 6.40149 14.1416 6 15.5 6C16.8584 6 18.0916 6.40149 19 7.05477V18C18.0916 17.3467 16.8584 16.9452 15.5 16.9452C14.1416 16.9452 12.9084 17.3467 12 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> 
)

export const FormIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M12 5V7.8C12 8.9201 12 9.48016 12.218 9.90798C12.4097 10.2843 12.7157 10.5903 13.092 10.782C13.5198 11 14.0799 11 15.2 11H18M13.0941 5H9.2C8.0799 5 7.51984 5 7.09202 5.21799C6.71569 5.40973 6.40973 5.71569 6.21799 6.09202C6 6.51984 6 7.0799 6 8.2V15.8C6 16.9201 6 17.4802 6.21799 17.908C6.40973 18.2843 6.71569 18.5903 7.09202 18.782C7.51984 19 8.07989 19 9.2 19H14.8C15.9201 19 16.4802 19 16.908 18.782C17.2843 18.5903 17.5903 18.2843 17.782 17.908C18 17.4802 18 16.9201 18 15.8V10.0576C18 9.59676 18 9.36635 17.9505 9.1481C17.9067 8.95454 17.8342 8.7686 17.7356 8.59637C17.6244 8.40216 17.4686 8.23246 17.1569 7.89306L15.451 6.0355C15.1006 5.65404 14.9255 5.46331 14.7166 5.32658C14.5314 5.20539 14.3275 5.11577 14.113 5.06137C13.871 5 13.612 5 13.0941 5Z" stroke="currentColor" stroke-width="2"/>
  </svg>
)


export const HomeIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M6.375 9.26786V16.5C6.375 17.7427 7.38236 18.75 8.625 18.75H15.375C16.6177 18.75 17.625 17.7427 17.625 16.5V9.26786M6.375 9.26786L4.125 10.875M6.375 9.26786L12 5.25L17.625 9.26786M17.625 9.26786L19.875 10.875" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const ChatIcon = buildCustomIcon( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M5.21799 6.09202C5 6.51984 5 7.0799 5 8.2V12.3C5 13.4201 5 13.9802 5.21799 14.408C5.40973 14.7843 5.71569 15.0903 6.09202 15.282C6.39717 15.4375 6.7696 15.4821 7.36454 15.4949V19L12.875 15.5L15.8 15.5C16.9201 15.5 17.4802 15.5 17.908 15.282C18.2843 15.0903 18.5903 14.7843 18.782 14.408C19 13.9802 19 13.4201 19 12.3V8.2C19 7.0799 19 6.51984 18.782 6.09202C18.5903 5.71569 18.2843 5.40973 17.908 5.21799C17.4802 5 16.9201 5 15.8 5H8.2C7.0799 5 6.51984 5 6.09202 5.21799C5.71569 5.40973 5.40973 5.71569 5.21799 6.09202Z" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
  </svg> 
)

export const MilestoneIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M6 19V14M6 14V7C6 5.89543 6.89543 5 8 5H18L15 9.5L18 14H6Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const MenuIcon = buildCustomIcon( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M5 7L19 7M5 17H19M5 12H19" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg> 
)
export const SlideMenu = MenuIcon

export const NotificationIcon = buildCustomIcon( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M14.9547 15.0158C15.2008 16.4925 14.5 19 12 19C9.5 19 8.80014 16.4925 9.04625 15.0158M12 5C10.5 5 9.26825 5.4635 8.5 7C7.5 9 9 12 6 15H18C15 12 16.5 9 15.5 7C14.7317 5.4635 13.5 5 12 5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const QuestionIcon = buildCustomIcon( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <circle cx="6" cy="5" r="2" stroke="currentColor" stroke-width="2"/>
  <circle cx="6" cy="12" r="2" stroke="currentColor" stroke-width="2"/>
  <circle cx="6" cy="19" r="2" stroke="currentColor" stroke-width="2"/>
  <line x1="11" y1="5" x2="20" y2="5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  <line x1="11" y1="12" x2="20" y2="12" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  <line x1="11" y1="19" x2="20" y2="19" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
  </svg> 
)

// for Privacy & Security menu item (not implemented yet?)
export const SecurityIcon = buildCustomIcon( 
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M15 8.0491V8C15 7.06812 15 6.60218 14.8478 6.23463C14.6448 5.74458 14.2554 5.35523 13.7654 5.15224C13.3978 5 12.9319 5 12 5C11.0681 5 10.6022 5 10.2346 5.15224C9.74458 5.35523 9.35523 5.74458 9.15224 6.23463C9 6.60218 9 7.06812 9 8L9 11M9.2 19H14.8C15.9201 19 16.4802 19 16.908 18.782C17.2843 18.5903 17.5903 18.2843 17.782 17.908C18 17.4802 18 16.9201 18 15.8V14.2C18 13.0799 18 12.5198 17.782 12.092C17.5903 11.7157 17.2843 11.4097 16.908 11.218C16.4802 11 15.9201 11 14.8 11H9.2C8.0799 11 7.51984 11 7.09202 11.218C6.71569 11.4097 6.40973 11.7157 6.21799 12.092C6 12.5198 6 13.0799 6 14.2V15.8C6 16.9201 6 17.4802 6.21799 17.908C6.40973 18.2843 6.71569 18.5903 7.09202 18.782C7.51984 19 8.07989 19 9.2 19Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> 
)

export const SettingsIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" stroke="currentColor" stroke-width="2"/>
  <path d="M10.2955 6.32852C10.81 4.55716 13.19 4.55716 13.7045 6.32852C13.9783 7.27138 14.8976 7.83057 15.8095 7.609C17.5227 7.19275 18.7127 9.36442 17.5139 10.7195C16.8758 11.4408 16.8758 12.5592 17.5139 13.2805C18.7127 14.6356 17.5227 16.8073 15.8095 16.391C14.8976 16.1694 13.9783 16.7286 13.7045 17.6715C13.19 19.4428 10.81 19.4428 10.2955 17.6715C10.0217 16.7286 9.10245 16.1694 8.19054 16.391C6.47735 16.8073 5.28734 14.6356 6.48608 13.2805C7.12415 12.5592 7.12415 11.4408 6.48608 10.7195C5.28734 9.36442 6.47735 7.19275 8.19054 7.609C9.10245 7.83057 10.0217 7.27138 10.2955 6.32852Z" stroke="currentColor" stroke-width="2"/>
  </svg> 
)

export const SidebarCloseIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M19.0106 4H15.0078V6.00141H18.0098V18.0096H15.0078V20.011H19.0106C19.2761 20.011 19.5305 19.9056 19.7183 19.718C19.9059 19.5303 20.0113 19.2758 20.0113 19.0104V5.00083C20.0113 4.73541 19.9059 4.48097 19.7183 4.29321C19.5305 4.10562 19.2761 4.00021 19.0106 4.00021V4Z" fill="black"/>
  <path d="M8.00351 16.4233L9.41961 15.0074L7.4182 13.006H16.0089V11.0047H7.4182L9.41961 9.00332L8.00351 7.5874L4.29352 11.2974C4.10558 11.4852 4 11.7398 4 12.0054C4 12.271 4.10558 12.5256 4.29352 12.7134L8.00351 16.4233Z" fill="black"/>
  </svg>  
)

export const SideBarOpenIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M5.00108 4H9.00391V6.00141H6.0019V18.0096H9.00391V20.011H5.00108C4.73566 20.011 4.48122 19.9056 4.29346 19.718C4.10587 19.5303 4.00046 19.2758 4.00046 19.0104V5.00083C4.00046 4.73541 4.10587 4.48097 4.29346 4.29321C4.48122 4.10562 4.73565 4.00021 5.00108 4.00021V4Z" fill="black"/>
  <path d="M16.0082 16.4233L14.5921 15.0074L16.5935 13.006H8.00284V11.0047H16.5935L14.5921 9.00332L16.0082 7.5874L19.7182 11.2974C19.9061 11.4852 20.0117 11.7398 20.0117 12.0054C20.0117 12.271 19.9061 12.5256 19.7182 12.7134L16.0082 16.4233Z" fill="black"/>
  </svg>
)

export const OpenIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M12.0009 20.824C12.2213 20.824 12.4331 20.7364 12.5888 20.5803C12.7449 20.4246 12.8325 20.2128 12.8325 19.9924V12.8606L13.9094 13.9334V13.9332C14.2395 14.2159 14.7313 14.1968 15.0385 13.8896C15.3457 13.5825 15.3645 13.0906 15.082 12.7608L12.587 10.2658C12.2628 9.94329 11.739 9.94329 11.4147 10.2658L8.91971 12.7608C8.63721 13.0906 8.65606 13.5825 8.96322 13.8896C9.27037 14.1968 9.76231 14.2159 10.0924 13.9332L11.1693 12.8606V19.9924C11.1693 20.2128 11.2569 20.4246 11.4129 20.5803C11.5687 20.7364 11.7805 20.824 12.0009 20.824Z" fill="black"/>
  <path d="M17.8225 8.42425C17.7343 7.27277 17.214 6.19731 16.3656 5.4136C15.5169 4.62988 14.4037 4.19625 13.2488 4.19974C11.9203 4.14811 10.6564 4.77432 9.89328 5.86281H9.09071C7.88821 5.86281 6.73442 6.33616 5.87816 7.18047C5.02227 8.02478 4.53325 9.17218 4.51669 10.3745C3.34553 10.8907 2.47739 11.9178 2.16331 13.1589C1.84919 14.3997 2.12415 15.7162 2.90871 16.7275C3.69325 17.7389 4.89983 18.3326 6.18014 18.337H8.67512H8.67483C9.13426 18.337 9.50637 17.9649 9.50637 17.5055C9.50637 17.046 9.13424 16.6739 8.67483 16.6739H6.18014C5.36747 16.6623 4.61129 16.2557 4.15391 15.5839C3.69622 14.9125 3.59441 14.0601 3.88098 13.2995C4.16754 12.539 4.80681 11.9656 5.59365 11.7632C6.01769 11.6257 6.27989 11.2016 6.21319 10.7611C6.19375 10.6541 6.18244 10.5456 6.18012 10.4368C6.18012 9.66475 6.4867 8.92454 7.03253 8.37865C7.57839 7.83278 8.31855 7.52625 9.09069 7.52625H10.3382C10.632 7.52277 10.9023 7.36469 11.0491 7.11032C11.257 6.71296 11.5752 6.38406 11.9653 6.16273C12.3555 5.9414 12.801 5.8373 13.2488 5.86282C14.0206 5.86282 14.761 6.1694 15.3069 6.71522C15.8528 7.26109 16.1593 8.00155 16.1593 8.77339V9.18931C16.1593 9.40975 16.2469 9.62148 16.403 9.77723C16.5587 9.93328 16.7704 10.0209 16.9909 10.0209C18.1795 10.0209 19.2776 10.6549 19.8716 11.6842C20.4659 12.7133 20.4659 13.9814 19.8716 15.0105C19.2776 16.0398 18.1795 16.6738 16.9909 16.6738H15.3278C14.8684 16.6738 14.4963 17.046 14.4963 17.5054C14.4963 17.9648 14.8684 18.3369 15.3278 18.3369H16.9909C18.6792 18.3436 20.2562 17.4964 21.1823 16.0847C22.1087 14.6734 22.2583 12.8893 21.5802 11.3431C20.9021 9.79722 19.4881 8.69882 17.8224 8.42417L17.8225 8.42425Z" fill="black"/>
  </svg>
)

export const VirtualVisitIcon = buildCustomIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" />
  <path d="M21.5139 5.93745C21.3661 5.84974 21.1985 5.80352 21.0277 5.80352C20.8572 5.80352 20.6895 5.84974 20.5417 5.93745L16.1429 8.54993C16.0354 7.5732 15.5819 6.67146 14.8692 6.01665C14.1565 5.36183 13.2343 5 12.2787 5H5.88854C4.85713 5 3.86813 5.42149 3.13886 6.17151C2.40972 6.92173 2 7.93909 2 9.00002V15C2 16.061 2.40974 17.0783 3.13886 17.8285C3.86817 18.5785 4.85717 19 5.88854 19H12.2787C13.2344 19 14.1566 18.6382 14.8692 17.9834C15.5819 17.3285 16.0354 16.4268 16.1429 15.4501L20.5417 18.0626C20.8425 18.2411 21.2127 18.2411 21.5135 18.0627C21.8142 17.8843 21.9997 17.5546 22 17.1976V6.80257C21.9997 6.44578 21.8143 6.11602 21.5139 5.93745ZM14.223 14.9999C14.223 15.5304 14.0181 16.039 13.6534 16.4142C13.2889 16.7893 12.7944 16.9999 12.2787 16.9999H5.88854C5.37283 16.9999 4.87835 16.7893 4.51368 16.4142C4.149 16.0391 3.94425 15.5304 3.94425 14.9999V8.99993C3.94425 8.46944 4.14904 7.96078 4.51368 7.58565C4.87832 7.21052 5.37283 6.9999 5.88854 6.9999H12.2787C12.7944 6.9999 13.2889 7.21056 13.6534 7.58565C14.0181 7.96075 14.223 8.46944 14.223 8.99993V14.9999ZM20.0558 15.4648L16.1672 13.1549V10.845L20.0558 8.53511V15.4648Z" fill="currentColor"/>
  </svg>
)