import React from "react"
import { Grid, Paper, Typography } from "@mui/material"
import {
  EnduserInfoEditor,
} from "./Settings"

export const Onboarding = () => {
  return (
    <Grid container alignItems="center" justifyContent="center"
      sx={{ backgroundColor: 'primary.main', height: '100vh' }}
    >
    <Paper elevation={3} sx={{ m: 2, p: 5, minHeight: 400, minWidth: 200, maxWidth: 800 }}>
      <Typography style={{ fontSize: 20, marginBottom: 15 }}>
        Please enter your information to finish the registration process 
      </Typography>
      <EnduserInfoEditor onboarding />
    </Paper>
    </Grid>
  )
}