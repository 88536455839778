export const MOBILE_WIDTH_CUTOFF = 1000 // in @mui, xs is under 0-600px

export const TellescopeHost = (
  process.env.REACT_APP_TELLESCOPE_HOST 
  || (
    window.location.origin.includes(':3035')
      ? window.location.origin.replace(':3035', ':8080')
      : 'https://api.tellescope.com'
  )
)

export const DASHBOARD_CHILD_MARGIN = '20px'
export const dashboardChildStyles = { padding: DASHBOARD_CHILD_MARGIN }

// replace with actual business id string value
export const BUSINESS_ID = (
  window.location.origin.includes(':30')
    ? "63a0d6be40e38ba9e899a490" // local Tellescope test business
    : "63f66fcd651aa84b7c6f9e8f"
)
export const BUSINESS_NAME = "Blokes and Joi"
export const ORGANIZATION_IDS = [] // replace with list of organizationIds if using a suborganization