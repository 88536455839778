import React, { useMemo } from "react"
import { LabeledIconButton } from "@tellescope/react-components"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Grid, Typography } from "@mui/material";
import { routes, useNavigateToPage } from "../../definitions/routes";
import { useCart } from "./CartContext";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Product } from "@tellescope/types-client";

export { ShoppingCartIcon as CartIcon }

export const ViewCartIconButton = () => {
  const navigate = useNavigateToPage()
  const { productIds } = useCart()

  return (
    <Badge badgeContent={productIds.length} color="primary">
    <LabeledIconButton Icon={ShoppingCartIcon} label="View Cart"
      onClick={() => navigate(routes.cart)}
    />
    </Badge>
  )
}

export const ToggleCartCount = ({ product } : { product: Product }) => {
  const { productIds, addToCart, removeFromCart } = useCart()
  
  const filtered = useMemo(() => productIds.filter(p => p === product.id), [productIds, product.id])

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <LabeledIconButton Icon={RemoveIcon} label="Remove One" 
          onClick={() => removeFromCart(product.id)} 
          disabled={filtered.length === 0}
        />
      </Grid>


      <Grid item>
        <Typography sx={{ fontSize: 20 }}>
          {filtered.length}
        </Typography>
      </Grid>


      <Grid item>
        <LabeledIconButton Icon={AddIcon} label="Add One"
          disabled={
            typeof product.maxCheckoutCount === 'number'
            && product.maxCheckoutCount <= filtered.length
          }
          onClick={() => addToCart(product.id)} 
        />
      </Grid>
    </Grid>
  )
}