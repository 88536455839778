import React, { useState } from "react"
import { Button, Grid, Paper, Typography } from "@mui/material"
import { PortalCustomization, User } from "@tellescope/types-client"
import { PortalBlockForType, PortalBlock, PortalBlockType,  PortalPage } from "@tellescope/types-models"
import { 
  DisplayPicture, LabeledIconButton, LoadingLinear, 
  useAssignedManagedContentRecords, 
  useEnduserSession, 
  usePortalCustomizations, useUsers, 
} from "@tellescope/react-components"
import { routes, useNavigateToPage } from "../definitions/routes"
import { user_display_name } from "@tellescope/utilities"
import { DASHBOARD_CHILD_MARGIN } from "../definitions/constants"
import { ContentList } from "./Content"
import { CarePlanOverview } from "./CarePlan"
import { Alerts } from "./Home"
import { first_letter_capitalized } from "@tellescope/validation"

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Events } from "./Events"

export const ResolveCustomPage = ({ page, defaultPageComponent } : { page: PortalPage, defaultPageComponent: React.ReactNode }) => {
  const [customizationsLoading] = usePortalCustomizations()

  return (
    <LoadingLinear data={customizationsLoading} render={customizations => {
      const customization = customizations.find(c => c.page === page)
      return (
        (customization && customization.blocks.length > 0)
          ? <CustomPage customization={customization} /> 
          : <>{defaultPageComponent}</>
      )
    }} />
  )
}

export const LoadedCareTeamDisplay: React.JSXElementConstructor<Pick<PortalBlockForType['careTeam'], 'info'> & { users: User[] }> = ({ 
  info,
  users,
}) => {
  const session = useEnduserSession()
  const navigate = useNavigateToPage()

  const careTeamMembers = users.filter(u => session.userInfo.assignedTo?.includes(u.id))

  // start with first assigned team member
  const [index, setIndex] = useState(0)

  const selected = careTeamMembers?.find(c => c.id === session.userInfo.assignedTo?.[index % session.userInfo.assignedTo?.length])

  return (
    <Paper elevation={2} sx={{ p: 2, pt: 1, pl: 3 }}>
      <Grid container alignItems="center" wrap="nowrap"
        sx={{ maxWidth: 600 }}
      >
        <Grid item>
          <DisplayPicture user={selected} size={125} />
        </Grid>
  
        <Grid item sx={{ marginLeft: 2, my: 2 }}>
        <Grid container direction="column" spacing={.25}>  
          {selected 
            ? (
              <>
              <Grid item>
              <Grid container alignItems="center">
                <Typography sx={{ fontSize: 15, opacity: .8 }}>
                  {info.title} 
                </Typography>
                {careTeamMembers.length > 0 && 
                  <LabeledIconButton Icon={NavigateNextIcon} label="See Others" onClick={() => setIndex(i => i+1)} />
                }
              </Grid>
              </Grid>

              <Grid item>
                <Typography sx={{ fontSize: 25, fontWeight: 'bold' }}>
                  {user_display_name(selected)}
                </Typography>
              </Grid>
  
              <Grid item>
                <Typography style={{ fontSize: 16, opacity: .8 }}>
                  {selected?.suffixes?.join(', ')}
                </Typography>
              </Grid>
              </>
            ) : (
              <>
                <Typography>
                  {first_letter_capitalized(info.title.toLowerCase())} is currently being assigned and will appear here shortly
                </Typography>
              </>
            )
          }
  
          <Grid item sx={{ mt: 1 }}>
            <Button variant="contained" color="secondary" 
              sx={{ 
                minHeight: 50, borderRadius: 15, 
                fontSize: 16, fontWeight: 'bold', textTransform: 'none',
              }}
              onClick={() => navigate(routes.communications)}
            >
              Contact {selected?.fname ?? 'Us'}
            </Button>
          </Grid>
        </Grid>
        </Grid>
      </Grid>
      </Paper>
  )
}

export const CareTeamDisplay: React.JSXElementConstructor<Pick<PortalBlockForType['careTeam'], 'info'>> = props => {
  const [usersLoading] = useUsers()
  return (
    <LoadingLinear data={usersLoading} render={users => <LoadedCareTeamDisplay {...props} users={users} />} />
  )
}

export const EducationDisplay: React.JSXElementConstructor<Pick<PortalBlockForType['education'], 'info'>> = () => {
  const contentRecordsLoading = useAssignedManagedContentRecords()
  
  return (
    <LoadingLinear data={contentRecordsLoading} render={records => (
    <Grid container sx={{ px: DASHBOARD_CHILD_MARGIN }}>
      <ContentList records={records} totalRecordsCount={records.length} 
        noCategories defaultContentTitle="Education"
      />
    </Grid>
    )} />
  )
}


export const CarePlanDisplay: React.JSXElementConstructor<Pick<PortalBlockForType['carePlan'], 'info'>> = () => {
  return <CarePlanOverview maxHeight={'50vh'} />
}

export const EventsDisplay: React.JSXElementConstructor<Pick<PortalBlockForType['Events'], 'info'>> = () => {
  return <Events style={{ maxHeight: '50vh' }} />
}

const portalBlockComponents: { [K in PortalBlockType ] : {
  Display: React.JSXElementConstructor<Pick<PortalBlockForType[K], 'info'>>,
} } = {
  careTeam: { Display: CareTeamDisplay },
  education: { Display: EducationDisplay },
  carePlan: { Display: CarePlanDisplay },
  Events: { Display: EventsDisplay },
  text: { Display: ({ info }) => (
    <Typography sx={{ fontSize: 20, m: DASHBOARD_CHILD_MARGIN }}>
      {info.text}
    </Typography>
  )}
}

export const CustomBlock = ({ block } : { block: PortalBlock }) => {
  const Display = portalBlockComponents[block.type].Display

  return <Display info={block.info as PortalBlock['info'] as any} />
}

export const CustomPage = ({ customization } : { customization: PortalCustomization }) => {
  return (
    <Grid container direction="column">
      {customization.page === 'Home' &&
        <Grid item>
          <Alerts sx={{ px: DASHBOARD_CHILD_MARGIN, pt: 1 }} />
        </Grid>
      }

      {customization.blocks.map((block, i) => (
        <Grid item key={i} sx={{ 
          mt: block.type === 'careTeam' ? 0 : 1, // care team paper must align nicely with topbar for Icon UI, has padding built-in
          mb: 1,
        }}>
          <CustomBlock block={block} />
        </Grid>
      ))}
    </Grid>
  )
}