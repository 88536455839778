import {
  Box,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import {
  EnduserProvider,
  LoadingButton,
  WithEnduserSession,
  useEnduserSession,
  useEnduserSessionContext,
} from "@tellescope/react-components";
import { getApiURL } from '@tellescope/utilities';
import {
  WithVideo,
  useCurrentCallContext,
  useJoinVideoCall,
} from "@tellescope/video-chat";
import React, { useEffect, useRef, useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useParams,
  useSearchParams
} from "react-router-dom";
import FormDisplay from './FormDisplay';
import { TERMS_VERSION, TermsOfServiceAndPrivacyPolicyConsent } from './components/agreements';
import { WithFileViewer } from './components/files';
import { AuthenticatedContainer, RedirectToExternalDashboard, WithVideoToast } from './components/navigation';
import { BUSINESS_ID, ORGANIZATION_IDS, TellescopeHost } from './definitions/constants';
import { routes } from './definitions/routes';
import { PublicAppointmentBookingPage } from './pages/AppointmentBooking/AppointmentBooking';
import { AppointmentScheduling, AppointmentSchedulingForTemplateRoute } from './pages/AppointmentScheduling';
import { CarePlanHome } from './pages/CarePlan';
import { Chat } from './pages/Chat';
import { Community } from './pages/Community';
import { Content, ContentViewer } from './pages/Content';
import { Documents, SubmitForm } from './pages/Documents';
import { Event, Events } from './pages/Events';
import { Home, WithSetInitialPasswordPrompt } from './pages/Home';
import { LandingPage } from './pages/Landing';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsOfService from './pages/Legal/TermsOfService';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { Onboarding } from './pages/Onboarding';
import { Register } from './pages/Register';
import { ResetPassword } from './pages/ResetPassword';
import { Settings } from './pages/Settings';
import { Unsubscribe } from './pages/Unsubscribe';
import { VideoCall } from './pages/VideoCall';
import { WithCart } from "./pages/ECommerce/CartContext";
import { CartPage } from "./pages/ECommerce/CartPage";
import { PurchasesLoading } from "./pages/ECommerce/Purchases";
import { Product } from "./pages/ECommerce/Product";

const LoadingPage = () => (
  <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
    <CircularProgress size={250} thickness={1} />
  </Grid>
)

export const App = () => (
  <ThemeProvider theme={createTheme({
    palette: {
      primary: {
        main: '#353736',
      },
      secondary: {
        main: '#81c48d',
        light: '#81c48d40',
      },
    },
    typography: {
      fontFamily: ["p22-underground"].join(',')
    }
  })}>
  <WithEnduserSession sessionOptions={{ 
    host: TellescopeHost,
    businessId: BUSINESS_ID,
    organizationIds: ORGANIZATION_IDS,
    handleUnauthenticated: async () => { window.location.href = routes.login },
  }}>
  <EnduserProvider>
  <Router>
    <Routing />
  </Router>
  </EnduserProvider>
  </WithEnduserSession>
  </ThemeProvider>  
);

const RedirectURL = () => {
  const token = useParams().token as string
  if (token) {
    window.location.href = (
      `${getApiURL()}/r/${token}`
    )
  }
  return <></>
}

const genericRoutes = [
  <Route key="/privacy-policy" path={'/privacy-policy'} element={<PrivacyPolicy />} />,
  <Route key="/terms-of-service" path={'/terms-of-service'} element={<TermsOfService />} />,
  <Route key="/appointment-booking-page" path={"/appointment-booking-page"} element={<PublicAppointmentBookingPage />} />,
  <Route key="/unsubscribe" path={"/unsubscribe"} element={<Unsubscribe />} />,
  <Route key="/r/:token" path={"/r/:token"} element={<RedirectURL />} />,
]

const UnauthenticatedAppRoutes = ({ hideRegister } : { hideRegister?: boolean }) => (
  <Box sx={{ overflow: 'hidden' }}>
  <Routes>
    <>{genericRoutes}</>
    <Route path={routes.landing} 
      element={hideRegister ? <Login /> : <LandingPage />} 
    />
    <Route path={routes.login} element={<Login />} />
    {hideRegister ? null : <Route path={routes.register} element={<Register />} />}
    <Route path={routes.reset_password} element={<ResetPassword />} />
    <Route path="/*" element={<Navigate replace to={routes.login} />} /> 
  </Routes>
  </Box>
)

const RequireTermsOfServiceAndPrivacyPolicy = ({ children, dontRequire } : { children: React.ReactElement, dontRequire: boolean }) => {
  const location = useLocation()
  const { enduserSession, updateUserInfo } = useEnduserSessionContext()
  const [consented, setConsented] = useState(false)

  // allow accessing these pages
  if (location.pathname.includes('/terms-of-service')) return children
  if (location.pathname.includes('/privacy-policy')) return children

  if (!enduserSession.userInfo.termsSigned && !dontRequire) {
    return (
      <Grid container alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
      <Grid container direction="column" sx={{ maxWidth: 300 }}>
        <Typography sx={{ fontSize: 20, mb: 1, textAlign: 'center' }}>
          You must accept our Terms of Service and Privacy Policy to continue  
        </Typography> 

        <Grid item sx={{ mt: 2, mb: 1 }}>
          <TermsOfServiceAndPrivacyPolicyConsent value={consented} onChange={setConsented} />
        </Grid>

        <LoadingButton submitText='Submit' submittingText="Saving..."
          disabled={!consented}
          onClick={() => updateUserInfo({
            termsSigned: new Date(),
            termsVersion: TERMS_VERSION,
          })} 
        />
      </Grid>
      </Grid>
    )
  }

  return children
}

const AuthenticatedAppRoutes = () => {
  const session = useEnduserSession()
  const location = useLocation()
  const [search] = useSearchParams()
  const redir = search.get("redir")
  const defaultRoute = redir || routes.home
  console.log(defaultRoute, location.pathname)

  const { meeting } = useCurrentCallContext()
  const { leaveMeeting } = useJoinVideoCall()

  useEffect(() => {
    if (meeting && !location.pathname.includes(routes.video)) {
      leaveMeeting()
    }
  }, [meeting, location, leaveMeeting])

  const dontShowNavigation = (
      location.pathname.includes('/r/') // redirecting
   || location.pathname.includes(routes.form_submit) // direct link to form
  )

  const sfRef = useRef(false)
  useEffect(() => {
    if ((session.userInfo as any).salesforceId) return
    if (sfRef.current) return
    sfRef.current = true

    session._GET('/v1/integrations/proxy-read', { integration: "Salesforce", type: 'contact-id' })
    .then(console.log)
    .catch(console.error)
  }, [session])

  return (
    // <RequireTermsOfServiceAndPrivacyPolicy
    //   // hide when filling out direct link to form, as it's separate from portal access
    //   dontRequire={dontShowNavigation} 
    // >
    <WithVideoToast>
    <WithFileViewer>
    <AuthenticatedContainer 
      hideNavigation={dontShowNavigation}
    >
    <WithCart>
    <Routes>
      <>{genericRoutes}</>
      <Route path={"external_dashboard"} element={<RedirectToExternalDashboard />} />
      <Route path={routes.home} element={<Home />} />
      <Route path={routes.video} element={<VideoCall />} />
      <Route path={routes.care_plan} element={<CarePlanHome  />} />

      <Route path={routes.communications}> 
        <Route path="" element={<Chat />}/> 
        <Route path=":roomId" element={<Chat />}/> 
      </Route>

      <Route path={routes.form_submit}> 
        <Route path="" element={<FormDisplay />}/> 
      </Route>


      <Route path={routes.appointment_booking}> 
        <Route path="" element={<AppointmentScheduling />}/> 
        <Route path=":pageId" element={<AppointmentSchedulingForTemplateRoute />}/> 
      </Route>

      <Route path={routes.content}> 
        <Route path="" element={<Content />}/> 
        <Route path=":id" element={<ContentViewer />}/> 
      </Route>

      <Route path={routes.events}> 
        <Route path="" element={<Events />}/> 
        <Route path=":id" element={<Event />}/> 
      </Route>

      <Route path={routes.products}> 
        {/* <Route path="" element={<Events />}/>  */}
        <Route path=":productId" element={<Product />}/>
      </Route>

      <Route path={routes.documents}> 
        <Route path="" element={<Documents />}/> 
      </Route>
      {/* alias in case needed */}
      <Route path={"files"}> 
        <Route path="" element={<Documents />}/> 
      </Route>

      <Route path={routes.forms}> 
        <Route path="" element={<Documents />}/> 
        <Route path=":accessCode" element={<SubmitForm />}/> 
      </Route>

      <Route path={routes.community}> 
        <Route path="" element={<Community />}/> 
      </Route> 

      <Route path={routes.cart}> 
        <Route path="" element={<CartPage />}/>
      </Route> 
      <Route path={routes.purchases}> 
        <Route path="" element={<PurchasesLoading />}/>
      </Route> 
     
      <Route path={routes.settings} element={<Settings />} />

      <Route path={routes.logout} element={<Logout />}/> 

      <Route path="/" element={<Navigate replace to={redir || "external_dashboard"}/>}/> 
      <Route path="/*" element={<Navigate replace to={defaultRoute}/>}/> 

      </Routes>
    </WithCart>
    </AuthenticatedContainer>
    </WithFileViewer>
    </WithVideoToast>
    // </RequireTermsOfServiceAndPrivacyPolicy>
  )
}

const Authenticating = ({ urlToken } : { urlToken: string }) => {
  const session = useEnduserSession()
  const { updateLocalSessionInfo, refresh } = useEnduserSessionContext()

  React.useEffect(() => {
    if (!urlToken) return // nothing to load
    if (urlToken && !session.authToken) { // include authToken in state
      updateLocalSessionInfo({}, urlToken)
      return
    }

    // make sure that the current URL token is used for refresh
    // ensures than if a new enduser is logged in on same device, that the new session is used
    session.setAuthToken(urlToken)

    // now that authToken is set, can perform authenticated requests
    // enduser value is currently unset, refresh is easy way to load without shortening existing authToken duration
    // if authToken is invalid, will trigger handleUnauthenticated
    refresh().catch(console.error)
  }, [urlToken, session, updateLocalSessionInfo, refresh])

  return <LoadingPage/>
}

const Routing = () => {
  const session = useEnduserSession()
  const location = useLocation()
  const { refresh } = useEnduserSessionContext()
  const lastRefreshRef = useRef(0)

  const [searchParams] = useSearchParams()
  const urlToken = searchParams.get('token')
  const lastTokenRef = useRef('')

  useEffect(() => {
    if (!session.authToken) return
    if (lastRefreshRef.current > Date.now() - 10000) return
    lastRefreshRef.current = Date.now()

    refresh().catch(console.error)
  }, [session, refresh])

  if (urlToken && urlToken !== lastTokenRef.current) {
    lastTokenRef.current = urlToken

    return (
      <Routes>
        <Route path="*" element={<Authenticating urlToken={urlToken} />}/>
      </Routes>
    )
  }
  
  if (!session.authToken) return <UnauthenticatedAppRoutes hideRegister />
  if (location.pathname === '/logout') return <Logout />
  if (location.pathname === '/appointment-booking-page') return <PublicAppointmentBookingPage />

  return (
    (session.userInfo.fname && session.userInfo.lname) // don't change without verifying public booking flow 
      ? (
        <WithSetInitialPasswordPrompt>
        <WithVideo>
          <AuthenticatedAppRoutes />
        </WithVideo>
        </WithSetInitialPasswordPrompt>
      )
      : <Onboarding />
  )
}

export default App;
