export enum BookingPage {
  Loading=0, // load info for booking page, display appointment type
  PickAppointmentType, // skip if only one calendar eventId is provided
  Calendar,
  Intake,
  Confirmation,
  Payment,
  ThankYou,
}

export type TrackingEvent = (
  'view'
  | 'selected_time'
  | 'completed_intake'
  | 'booked'
)