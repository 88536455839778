import React, { useEffect, useRef, useState } from "react"
import { useEnduserSession } from "@tellescope/react-components"
import { Chip, Divider, Grid, LinearProgress, Typography } from "@mui/material"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formatted_date } from "@tellescope/utilities";

type PurchaseResponse = {
  orders: {
    records: {
      "attributes": {
        "type": "Website_Order__c",
        "url": "/services/data/v60.0/sobjects/Website_Order__c/id_here"
      },
      Date_Created__c: string,
      Id: string,
      "Name": "order-1714159779",
      "Line_Items__c": "01tUI0000034Q0oYAE,01tUI0000034Hq9YAE",// comma separated ids
      "Order_Total__c": number, // price in dollars (e.g. 600 is $600)
      "Items_Total__c": number,
      "Completed__c": boolean,
    }[]
  },
  items: {
    records: {
      "attributes": {
        "type": "Website_Order_Line_Item__c",
        "url": "/services/data/v60.0/sobjects/Website_Order_Line_Item__c/id_here"
      },
      "Product_Name__c": "Initial Doctor Consult",
      "Price_At_Purchase__c": number, // dollars
      "Price_Total_At_Purchase__c": number, // dollars
      "Quantity__c": number,
      "Variant_Name__c": "General",
      Website_Order__c: string,
    }[]
  },
}

export const PurchasesLoading = () => {
  const session = useEnduserSession()
  const [purchases, setPurchases] = useState<PurchaseResponse>()

  const fetchRef = useRef(false) 
  useEffect(() => {
    if (fetchRef.current) return
    fetchRef.current = true

    session._GET<any, { data: any }>('/v1/integrations/proxy-read', { integration: "Salesforce", type: 'purchases' })
    .then(r => setPurchases(r.data))
    .catch(err => {
      console.error(err)
      setPurchases({ orders: { records: [] }, items: { records: [] }})
    })
  }, [session])

  if (!purchases) return <LinearProgress />
  if (purchases.orders.records.length === 0) return <Typography>No orders found</Typography>
  return (
    <div>
      <Typography sx={{ fontWeight: 'bold', fontSize: 25, mb: 1 }}>
        Your Orders
      </Typography>

      {purchases.orders.records.map((order, i) => {
        const items = purchases.items.records.filter(i => i.Website_Order__c === order.Id)

        return (
          <Accordion key={order.Id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i}-content`}
            id={`panel${i}-header`}
          >
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
              <Grid item>
              <Grid container alignItems={"center"} wrap="nowrap" spacing={1}>
                <Grid item>
                <Grid container wrap="nowrap" alignItems={"center"}>
                  <Typography sx={{ fontSize: 15, width: 155 }}>
                    {formatted_date(new Date(order.Date_Created__c))} 
                  </Typography>

                  <Typography sx={{ fontSize: 15, width: 55 }}>
                    (${order.Order_Total__c})
                  </Typography>
                </Grid>
                </Grid>

                {order.Completed__c && 
                  <Grid item>
                    <Chip label="Completed" size="small" color="primary" />
                  </Grid>
                }
              </Grid>
              </Grid>

              <Grid item sx={{ mr: 1 }}>
                <Typography>{order.Name}</Typography>
              </Grid>
            </Grid>
            
          </AccordionSummary>

          <AccordionDetails>
          <Grid container direction="column" spacing={1}>
            <Grid item sx={{ mb: 1 }}>
              <Divider flexItem />
            </Grid>

            {items.map((item, j) => (
              <Grid item key={j}>
              <Grid container spacing={1}>
                <Grid item sx={{ width: 60 }}>
                  <Typography>
                    ${item.Price_At_Purchase__c}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {item.Product_Name__c}{item.Variant_Name__c ? ` (${item.Variant_Name__c})` : ''}
                  </Typography>
                </Grid>
              </Grid>
              </Grid>
            ))}
          </Grid>
          </AccordionDetails>
          </Accordion>
          )
      })}
    </div>
  )
}