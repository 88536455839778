import * as Yup from "yup"
import { create_input_schema } from "@tellescope/react-components"
import isPhone from 'validator/lib/isMobilePhone';


export const password = Yup.string()
                      .min(8)
                      .max(100)
                      .matches(/[a-z]/, "Must contain a lowercase letter")
                      .matches(/[A-Z]/, "Must contain an uppercase letter")
                      .matches(/[0-9@#!$%^&*(){}[\];:\\|'",<.>/?]/, "Must contain a number or special character")


export const accountFields = create_input_schema({
  email: { 
    validator: Yup.string().email('Enter a valid email').required(),
    initialValue: '',
    label: "Email",
  },
  password: {
    validator: password,
    initialValue: '',
    label: "Password",
  }
})
export const accountFieldsRelaxed = create_input_schema({
  email: { 
    validator: Yup.string().email('Enter a valid email').required(),
    initialValue: '',
    label: "Email",
  },
  password: {
    validator: Yup.string().required('Enter a password'),
    initialValue: '',
    label: "Password",
  }
})


export const yupPhoneRequiredValidator = Yup.string().test(
  'phone', 
  'Enter a valid phone number', 
  s => (
    isPhone((s ?? '').replaceAll(/[^0-9]/g, '') ?? '')
  )
)
export const accountInfo = create_input_schema({
  fname: { 
    validator: Yup.string().required(),
    initialValue: '',
    label: "First Name",
  },
  lname: { 
    validator: Yup.string().required(),
    initialValue: '',
    label: "Last Name",
  },
  email: { 
    validator: Yup.string().email('Enter a valid email').required(),
    initialValue: '',
    label: "Email",
  },
  phone: { 
    validator: Yup.string().test(
      'phone', 
      'Enter a valid phone number', 
      s => (
        !s || // allow optional
        isPhone(s.replaceAll(/[^0-9]/g, '') ?? '')
      )
    ).required(),
    initialValue: '',
    label: "Phone",
  },
  addressLineOne: { 
    validator: Yup.string().required(),
    initialValue: '',
    label: "Address",
  },
  addressLineTwo: { 
    validator: Yup.string(),
    initialValue: '',
    label: "Address Line 2",
  },
  city: { 
    validator: Yup.string(),
    initialValue: '',
    label: "City",
  },
  state: { 
    validator: Yup.string().min(2).max(2),
    initialValue: '',
    label: "State",
  },
  zipCode: { 
    validator: Yup.string().min(5).max(5),
    initialValue: '',
    label: "ZIP Code",
  },
})

export const yupDateMMDDYYYYValidatorRequired = (
  Yup.string()
  .test(
    'mm-dd-yyyy', 
    'Date must be MM-DD-YYYY', 
    s => /^\d{2}-\d{2}-\d{4}$/.test(s ?? '')
  )
)