import { Button, Grid, Typography } from "@mui/material"
import React, { useState } from "react"

const useFileViewer____internal = () => {
  const [file, setFile] = useState<{ name: string, url: string }>()

  return {
    file,
    setFile,
  }
}
const HEADER_HEIGHT = 50
const PADDING = 12
const FileViewerContext = React.createContext({} as ReturnType<typeof useFileViewer____internal>)
export const WithFileViewer = ({ children } : { children: React.ReactNode }) => {
  const value = useFileViewer____internal()

  if (value.file?.url) {
    return (
      <Grid container direction="column" sx={{ p: `${PADDING}px` }}>
        <Grid container alignItems="center" sx={{ height: HEADER_HEIGHT }}>
          <Button variant="outlined" color="primary" onClick={() => value.setFile(undefined)}>
            Back
          </Button>

          <Typography sx={{ pl: 1, fontSize: 20, }}>
            {value.file.name}
          </Typography>
        </Grid>
        
        <Grid item style={{ paddingTop: PADDING }}>
          <iframe title={value.file.name} src={value.file.url} style={{ border: 'none' }}
            width={window.innerWidth - PADDING * 2} 
            height={window.innerHeight - HEADER_HEIGHT - PADDING * 4} 
          />
        </Grid>
      </Grid>
    )
  }
  return (
    <FileViewerContext.Provider value={value}>
      {children}
    </FileViewerContext.Provider>
  )
}
export const useFileViewer = () => React.useContext(FileViewerContext)