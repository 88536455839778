import React, { useMemo } from "react"
import { LoadingLinear, useProducts } from "@tellescope/react-components"
import { Product } from "@tellescope/types-client"
import { Fade, Grid, Typography } from "@mui/material"
import { ToggleCartCount } from "./CartIcons"
import { useCart } from "./CartContext"
import { truncate_string } from "@tellescope/utilities"
import { routes, useNavigateToPage } from "../../definitions/routes"

export const ProductCard = ({ product } : { product: Product }) => {
  const navigate = useNavigateToPage()

  return (
    <Grid container direction="column"
      sx={{ width: 275, cursor: 'pointer' }}
      onClick={() => navigate(routes.products, { id: product.id })}
      // onMouseEnter={() => setHovering(product.id)}
      // onMouseLeave={() => setHovering('')}
    >
      <Grid item sx={{ 
        p: 4,
        // backgroundColor: product.id === hovering ? 'secondary.light' : '#dfdfdf', 
        backgroundColor: 'secondary.light',
      }}>
      <Grid container justifyContent={"center"}>
        <img src={product.image} style={{ height: 135 }} />
      </Grid>
      </Grid>

      <Grid item sx={{ 
        p: 2, textAlign: 'center',
        backgroundColor: 'primary.main', 
      }}>
        <Typography sx={{ fontSize: 18, color: '#dddddd' }}>
          {product.title}
        </Typography> 

        {(product.description /*&& hovering === product.id*/) && 
          <Fade in={true}>
          <Typography sx={{ fontSize: 14, color: '#ffffff', mt: 1  }}>
            {truncate_string(product.description, { length: 150 })}
          </Typography> 
          </Fade>
        }
      </Grid>

      {/* {hovering === product.id &&  */}
        <Grid item alignSelf="center">
          <ToggleCartCount product={product} />
        </Grid>
      {/* } */}
    </Grid>
  )
}

const Products = ({ products } : { products: Product[] }) => {
  const { getProductsByCategory } = useCart()
  // const [hovering, setHovering] = useState('')

  const productsByCategory = useMemo(() => getProductsByCategory(products), [products, getProductsByCategory])

  return (
    <Grid container direction="column" spacing={1} wrap="nowrap"
      // sx={{ maxHeight: 700, overflowY: 'auto' }}
    >
    {Object.keys(productsByCategory).map(category => (
      <Grid item key={category}>
      <Grid container direction="column">
        <Grid item>
        <Typography sx={{ fontSize: 20 }}>
          {category}
        </Typography>
        </Grid>

        <Grid item>
        <Grid container spacing={1}
          sx={{ 
            maxWidth: 850,
            // maxWidth: (
            //   width > MOBILE_WIDTH_CUTOFF && width < 1000
            //     ? '50vw'
            //     : '95vw'
            // ), 
            // overflowX: "auto" 
          }}
        >
        {productsByCategory[category].map(product => (
          <Grid item key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
        </Grid>
        </Grid>
      </Grid>
      </Grid>
    ))}
    </Grid>
  )
}

export const ProductsLoading = () => {
  const [, { filtered }] = useProducts({ 
    loadFilter: { showInPortal: true }
  })

  return (
    <LoadingLinear data={filtered(p => p.showInPortal === true)} 
      render={products => <Products products={products} />}
    />
  )
}