import { object_is_empty } from "@tellescope/utilities"
import {
  useNavigate,
} from "react-router-dom"

// use to enforce prefix '/' in route paths while preserving defined keys
const createRoutes = <T extends { [index: string]: `/${string}`}> (routes: T) => routes

export const routes = createRoutes({
  landing: '/',
  login: '/login',
  logout: '/logout',
  register: '/register',
  reset_password: '/reset-password',
  appointment_booking: '/book-an-appointment',
  home: '/home',
  video: '/video-call',
  care_plan: '/care-plan',
  events: '/events',
  communications: '/communications',
  content: '/content',
  documents: '/documents',
  forms: '/documents/forms',
  community: '/community',
  settings: '/settings',
  form_submit: '/link/form',
  cart: '/cart',
  checkout: '/cart/checkout',
  purchases: '/purchases',
  products: '/products',
})

export type Routes = typeof routes
export type RouteName = keyof typeof routes
export type BasePath = typeof routes[RouteName]

const to_query_string = (query?: Record<string, string>): string => {
  let queryString = ''

  if (query && !object_is_empty(query)) {
    queryString = '?'
    for (const key in query) {
      queryString += `${key}=${query[key]}&`
    } 
  }

  return queryString
}

// use instead of useNavigate to avoid calling navigate directly with generic strings
// limit to pre-defined, consistent, and typed routes
export const useNavigateToPage = () => {
  const navigate = useNavigate()
  return (page: BasePath, options?: { id?: string, query?: Record<string, string> }) => (
    navigate(`${page}${options?.id ? `/${options.id}` : ''}${to_query_string(options?.query)}`)
  )
}

export const useGoBack = () => {
  const navigate = useNavigate() 
  return (back=-1) => navigate(back)
}